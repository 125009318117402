import { Dispatch, useCallback, useContext, useMemo, useState } from 'react'
import { AppContext } from './context'
import useBreakpoint from 'use-breakpoint'
import getConfig from 'next/config'
const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()
export function useApp() {
	return useContext(AppContext)
}

export function useSplashScreen() {
	const { setShowSplashScreen } = useContext(AppContext)
	return {
		show: () => setShowSplashScreen(true),
		hide: () => setShowSplashScreen(false),
	}
}

export function useAppReady() {
	const { setReady } = useContext(AppContext)
	return () => setReady(true)
}

export function useConfig() {
	return {
		publicRuntimeConfig: publicRuntimeConfig as PublicRuntimeConfig,
		serverRuntimeConfig,
	}
}

export interface PublicRuntimeConfig {
	apiUrl: string
	lineNotifyId: string
	map: {
		google: string
		mapbox: string
	}
	layer: {
		parcel: LayerConfig
	}
}

export interface LayerConfig {
	id: string
	url: string
}

/**
 * It is important to bind the object of breakpoints to a variable for memoization to work correctly.
 * If they are created dynamically, try using the `useMemo` hook.
 */
const BREAKPOINTS = {
	xs: 0,
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	xxl: 1536,
} as const

export function useResponsive() {
	const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS)
	const { subPage } = useApp()
	const isDesktop = useMemo(() => {
		return breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl'
	}, [breakpoint])
	const isPhone = useMemo(() => {
		return breakpoint === 'md' || breakpoint === 'sm' || breakpoint === 'xs'
	}, [breakpoint])

	const isPhoneProtrait = useCallback(() => {
		return breakpoint === 'sm' || breakpoint === 'xs'
	}, [breakpoint])

	const isSubPage = useCallback(() => {
		return subPage?.title
	}, [subPage])

	return {
		isDesktop,
		isPhone,
		isPhoneProtrait,
		breakpoint,
		isSubPage,
		xs:
			breakpoint === 'xs' ||
			breakpoint === 'sm' ||
			breakpoint === 'md' ||
			breakpoint === 'lg' ||
			breakpoint === 'xl' ||
			breakpoint === 'xxl',
		sm:
			breakpoint === 'sm' ||
			breakpoint === 'md' ||
			breakpoint === 'lg' ||
			breakpoint === 'xl' ||
			breakpoint === 'xxl',
		md: breakpoint === 'md' || breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl',
		lg: breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl',
		xl: breakpoint === 'xl' || breakpoint === 'xxl',
		xxl: breakpoint === 'xxl',
	}
}

export function useLocalStorage<T = any>(key: string, initialValue?: T): [T, Dispatch<T>] {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		if (typeof window === 'undefined') {
			return initialValue
		}
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key)
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue
		} catch (error) {
			// If error also return initialValue
			console.error(error)
			return initialValue
		}
	})
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	function setValue(value: any) {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value
			// Save state
			setStoredValue(valueToStore)
			// Save to local storage
			if (typeof window !== 'undefined') {
				window.localStorage.setItem(key, JSON.stringify(valueToStore))
			}
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.error(error)
		}
	}
	return [storedValue, setValue]
}
