import { Button } from '@/components/common/button/Button'
import { Typography, Divider, Spin } from 'antd'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { GetIncidentNotificationDtoOut } from '@interface/dto/mis/incident.dto-out'
import { IncidentNotificationItem } from './IncidentNotification'
import { useMicroservice } from '@/contexts/service'
import { useApp, useResponsive } from '@/contexts/app'
import Body from '@/components/common/text/Body'
import { gaEvent } from '@/utils/google-analytic'

export interface NotificationProps extends PropsWithChildren {}

export const Notification: FC<NotificationProps> = (props) => {
	const service = useMicroservice()
	const screen = useResponsive()
	const { openNotification, setOpenNotification, setNotificationCount } = useApp()
	const [loading, setLoading] = useState<Boolean>(false)
	const [notificationData, setNotificationData] = useState<{ data: GetIncidentNotificationDtoOut[]; total: number }>({
		data: [],
		total: 0,
	})

	useEffect(() => {
		if (openNotification) {
			clearNotificationCount()
			fetchNotification()
		} else {
			setNotificationData({
				data: [],
				total: 0,
			})
		}
	}, [openNotification])

	const clearNotification = () => {
		window.dataLayer.push({ event: gaEvent.navbarClickButtonDeleteAllInListNotificationIn })

		service.mis.deleteIncidentNotification()
		setOpenNotification(false)
		setNotificationData({
			data: [],
			total: 0,
		})
	}

	const clearNotificationCount = () => {
		service.mis.clearIncidentNotificationCount().then((data) => {
			if (data.success) {
				setNotificationCount(0)
			}
		})
	}
	const fetchNotification = () => {
		setLoading(true)
		const pageSize = 5
		service.mis
			.getIncidentNotification({
				currentPage: Math.floor(notificationData.data.length / pageSize) + 1,
				pageSize: pageSize,
			})
			.then((res) => {
				setNotificationData((prev) => ({
					data: res?.data ? prev.data.concat(res.data) : prev.data,
					total: res.total || 0,
				}))
				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
			})
	}

	return (
		<>
			{openNotification && (
				<div
					id='scrollableDiv'
					className={
						screen.isPhone
							? 'w-full overflow-y-auto p-5'
							: 'absolute top-14 right-6 z-10 max-h-[400px] w-[570px] overflow-y-auto rounded-md bg-white p-5'
					}
				>
					<div className='flex items-center justify-between'>
						<Body>
							<Typography className='text-md'>การแจ้งเตือน</Typography>
						</Body>
						<Button className='text-md' onClick={clearNotification}>
							ลบทั้งหมด
						</Button>
					</div>
					<Divider className='my-5' />
					{!!notificationData.data?.length && (
						<div>
							{notificationData.data.map((item, index) => {
								return (
									<div key={item.id}>
										<IncidentNotificationItem data={item} />
										{index !== notificationData.data.length - 1 && <Divider className='my-5' />}
									</div>
								)
							})}
							{!loading && notificationData.data.length !== notificationData.total && (
								<div className='hover:cursor-pointer' onClick={fetchNotification}>
									<Divider className='my-5' />
									<div className='flex justify-center'>
										<Body className='text-md'>แจ้งเตือนเพิ่มเติม</Body>
									</div>
								</div>
							)}
						</div>
					)}
					{!loading && !notificationData.data?.length && (
						<div className='mt-10 mb-10 flex justify-center'>
							<Typography className='text-lg text-[#C1C1C1]'>ไม่มีการแจ้งเตือนใหม่</Typography>
						</div>
					)}
					{loading && (
						<div className='m-10 flex items-center justify-center'>
							<Spin />
						</div>
					)}
				</div>
			)}
		</>
	)
}
