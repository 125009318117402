export default function BellNotification({ className }: { className?: HTMLElement['className'] }) {
	return (
		<svg width='24' height='24' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M23.9027 20.0259C22.4674 18.2693 21.4542 17.3751 21.4542 12.5323C21.4542 8.09743 19.1895 6.51744 17.3256 5.75007C17.078 5.64836 16.845 5.41474 16.7695 5.16044C16.4426 4.04768 15.526 3.06738 14.3076 3.06738C13.0892 3.06738 12.172 4.04824 11.8484 5.16156C11.773 5.41865 11.5399 5.64836 11.2923 5.75007C9.4262 6.51855 7.1638 8.09296 7.1638 12.5323C7.161 17.3751 6.14773 18.2693 4.71249 20.0259C4.11782 20.7536 4.63871 21.8462 5.67881 21.8462H22.9419C23.9764 21.8462 24.494 20.7502 23.9027 20.0259Z'
				stroke='#00AA86'
				strokeWidth='2.38462'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M17.8844 21.8452V22.7394C17.8844 23.6881 17.5076 24.5979 16.8368 25.2687C16.166 25.9395 15.2562 26.3164 14.3075 26.3164C13.3589 26.3164 12.449 25.9395 11.7782 25.2687C11.1074 24.5979 10.7306 23.6881 10.7306 22.7394V21.8452'
				stroke='#00AA86'
				strokeWidth='2.38462'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}
