import { ServerResponse } from 'http'

export default function HealthCheck() {
    return <></>
}

export const getServerSideProps = async ({ res }: { res: ServerResponse }) => {
    res.setHeader('Content-Type', 'application/json; charset=utf-8')
    res.write('{ "responseCode" : 200, "message": "OK" }')
    res.end()
    return {
        props: {},
    }
}
