import SplashScreen from '@/components/SplashScreen'
import { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react'
interface AppContextValue {
	appUrl: string
	modalOpen: boolean
	ready: boolean
	showSplashScreen: boolean
	subPage: SubPageConfig | null
	openNotification: boolean
	notificationCount: number
}
interface AppContextFunction {
	setReady: (show: boolean) => void
	setShowSplashScreen: (show: boolean) => void
	setSubPage: (config: SubPageConfig | null) => void
	setOpenNotification: (open: boolean) => void
	setNotificationCount: (count: number) => void
}
type AppContextProps = AppContextValue & AppContextFunction

interface SubPageConfig {
	title: string
}

const defaultValue: AppContextValue = {
	appUrl: '',
	modalOpen: false,
	ready: false,
	showSplashScreen: false,
	subPage: null,
	openNotification: false,
	notificationCount: 0,
}

export const AppContext = createContext<AppContextProps>({
	...defaultValue,
	setReady(show: boolean) {},
	setShowSplashScreen(show: boolean) {},
	setSubPage(config: SubPageConfig | null) {},
	setOpenNotification(open: boolean) {},
	setNotificationCount(open: number | 0) {},
})

export interface AppProviderProps extends PropsWithChildren {
	appUrl: string
	isOfficer: boolean
}

export function AppProvider({ children, appUrl, isOfficer }: AppProviderProps) {
	const [ready, setReady] = useState<boolean>(false)
	const [showSplashScreen, setShowSplashScreen] = useState<boolean>(false)
	const [subPage, setSubPage] = useState<SubPageConfig | null>(null)
	const [openNotification, setOpenNotification] = useState<boolean>(false)
	const [notificationCount, setNotificationCount] = useState<number>(0)

	const contextValue = useMemo<AppContextProps>(() => {
		return {
			...defaultValue,
			appUrl,
			ready,
			setReady,
			showSplashScreen,
			setShowSplashScreen,
			subPage,
			setSubPage,
			openNotification,
			setOpenNotification,
			notificationCount,
			setNotificationCount,
		}
	}, [
		appUrl,
		ready,
		setReady,
		showSplashScreen,
		setShowSplashScreen,
		subPage,
		setSubPage,
		openNotification,
		setOpenNotification,
		notificationCount,
		setNotificationCount,
	])

	return (
		<>
			<AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
			<SplashScreen show={!ready || showSplashScreen} />
		</>
	)
}
