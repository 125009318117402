import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { useMicroservice } from '@/contexts/service'
import { sleep } from '@/utils/sleep'
import { useUser } from '@/contexts/user'
import { Modal } from '@/components/common/modal/Modal'
import { AuthType, OfficerUserLevel } from '@interface/config/am.config'
import { LineContext } from '@/contexts/line/context'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const { authUrlPeople, authAppIdPeople, authUrlOfficer, authAppIdOfficer, authAppSecretOfficer, authOfficerVersion } =
	publicRuntimeConfig

export interface IdentityContextValue {
	apiUrl: string
	token: string | undefined | void
	isOfficer: boolean
	signIn: () => Promise<void>
	signOut: () => Promise<unknown>
	register: () => Promise<void>
}

const defaultValue: IdentityContextValue = {
	apiUrl: '',
	token: '',
	isOfficer: false,
	async signIn() {},
	async signOut() {},
	async register() {},
}

export const IdentityContext = createContext<IdentityContextValue>(defaultValue)

interface IdentityContextProps extends PropsWithChildren {
	isOfficer: boolean
}

export function IdentityProvider({ isOfficer, children }: IdentityContextProps) {
	const [token, setToken] = useState<string | undefined | void>()
	const service = useMicroservice()
	const user = useUser()
	const { defaultLiffObject } = useContext(LineContext)
	const router = useRouter()
	const href = useMemo(
		() => (typeof location !== 'undefined' ? `${location.protocol}//${location.host}${router.basePath}` : ''),
		[]
	)

	const validateLineUser = async (token: string | undefined) => {
		if (!defaultLiffObject) return
		const liffObject = defaultLiffObject.liff

		// ถ้าเปิดผ่าน line
		if (liffObject?.isInClient() && liffObject.isLoggedIn()) {
			const channelId = liffObject?.id?.split('-')[0] as string
			const lineToken = liffObject?.getIDToken() as string
			const validate = await service.mis.validateAnonymous({
				lineToken: lineToken,
				channelId: channelId,
			})
			if (token) {
				// ถ้า login bedrock แล้ว
				if (!validate?.valid && validate?.lineUserId) {
					await service.mis.addLine({
						lineToken: lineToken,
						lineUserId: validate?.lineUserId,
						lineOAId: defaultLiffObject.lineOAId,
					})
				}
			} else {
				// ถ้าไม่ได้ login bedrock
				if (validate?.valid) {
					setToken(`${AuthType.Line}-${channelId}-${lineToken}`)
					service.updateAccessToken(`${AuthType.Line}-${channelId}-${lineToken}`)
					return lineToken
				}
			}
		}
		return token
	}

	const setProfile = async () => {
		const liffObject = defaultLiffObject?.liff
		try {
			const [profile] = await Promise.all([service.mis.syncProfile(user.isOfficer)])
			const isOfficer = profile.role === 'officer'
			const isDisabled = isOfficer && profile.isDisabled
			const conditionAdminOrUser =
				isOfficer &&
				(profile.userLevel === OfficerUserLevel.Admin || profile.userLevel === OfficerUserLevel.User) &&
				(!profile.officerArea || !profile.officerRoles?.length)
			if (isOfficer && (conditionAdminOrUser || isDisabled)) {
				//#region check officer role & ban
				Modal.error({
					icon: false,
					title: 'ระบบได้รับคำขอสำหรับขอเข้าใช้งานระบบแล้ว',
					content: (
						<>
							เนื่องจากท่านยังไม่ได้รับสิทธิ์เพื่อเข้าใช้งาน หรือถูกระงับการใช้งาน
							<br />
							กรุณารอเจ้าหน้าที่กำหนดสิทธิ์ หรือติดต่อผู้ดูแลระบบ
						</>
					),
					okButtonProps: { className: 'hidden' },
					cancelText: 'ออกจากระบบ',
					okCancel: true,
					closable: false,
				}).then(() => {
					if (liffObject?.isInClient()) {
						localStorage.removeItem('bedrockAccessToken')
						localStorage.removeItem('bedrockRefreshToken')
						user.unset()
					} else {
						signOut().then(() => {
							user.unset()
						})
					}
				})
				//#endregion
			} else {
				user.set({ ...profile })
				//#region บันทึกผู้แจ้งหลังเข้าสู่ระบบ
				const tempIncId = sessionStorage?.getItem('tempInc')
				if (tempIncId) {
					try {
						await service.mis.updateIncident({ id: tempIncId })
						sessionStorage.removeItem('tempInc')
					} catch (error) {
						console.error('save temp inc fail. ', error)
					}
				}
				//#endregion
				const sessionCallback = sessionStorage?.getItem('session-callback')
				const callback = { pathname: '/', query: {} }
				if (sessionCallback) {
					sessionStorage.removeItem('session-callback')
					try {
						const { callback: pathname, data } = JSON.parse(sessionCallback)
						callback.pathname = pathname
						callback.query = { data: JSON.stringify(data) }
						router.replace(callback)
					} catch (err) {}
				}
			}
		} catch (error) {
			console.error(error)
		}
	}

	const iniAuth = async () => {
		const isRedirectFromLiff = router.query.liffClientId
		const isRedirectFromLogout = router.query.fromLogout === 'true'

		// ดักไว้กัน query params ชนกันระหว่าง bedr & LINE และตรวจ user.profile เอาไว้ทำตอนไม่มี profile เท่านั้น
		if (!isRedirectFromLiff && !isRedirectFromLogout && !!defaultLiffObject?.liff && !user.profile) {
			let token: string | undefined

			if (isOfficer && authOfficerVersion === '3') {
				const { initBedrockAuth, verifyToken, getProfile } = await import('@arv-bedrock/auth-sso')
				initBedrockAuth({
					clientId: authAppIdOfficer,
					authUri: authUrlOfficer,
					clientSecret: authAppSecretOfficer,
					async callback() {
						// const validToken = await verifyToken()
						// if (validToken?.statusResponse?.statusCode === '200') {
						// 	token = 'cookie'
						// } else {
						// 	const resProfile = await getProfile()
						// 	if (resProfile?.statusResponse?.statusCode === '200') {
						// 		token = 'cookie'
						// 	}
						// }
						const resProfile = await getProfile()
						if (resProfile?.statusResponse?.statusCode === '200') {
							token = 'cookie'
						}
						token = await validateLineUser(token)
						if (!token) {
							user.unset()
							setToken(undefined)
							return
						}
						sleep(1000)
						setProfile()
					},
				})
			} else {
				const { initBedrockAuth, getToken } = await import('@arv-bedrock/auth')
				initBedrockAuth({
					clientId: authAppIdPeople,
					authUri: authUrlPeople,
					async callback() {
						token = getToken()
						if (token) {
							setToken(`${AuthType.Arv}-${token}`)
							service.updateAccessToken(`${AuthType.Arv}-${token}`)
						}
						token = await validateLineUser(token)
						if (!token) {
							user.unset()
							setToken(undefined)
							return
						}
						sleep(1000)
						setProfile()
					},
				})
			}
		}
	}

	useEffect(() => {
		iniAuth()
	}, [user.isOfficer, router.query, defaultLiffObject])

	const signIn = useCallback<() => Promise<void>>(
		() =>
			isOfficer && authOfficerVersion === '3'
				? import('@arv-bedrock/auth-sso').then(({ doLogin }) => doLogin(href))
				: import('@arv-bedrock/auth').then(({ doLogin }) => doLogin(href)),
		[href]
	)

	const signOut = useCallback<() => Promise<unknown>>(
		() =>
			isOfficer && authOfficerVersion === '3'
				? import('@arv-bedrock/auth-sso').then(({ doLogout }) => doLogout())
				: import('@arv-bedrock/auth').then(({ doLogout }) => doLogout()),
		[href]
	)

	const register = useCallback<() => Promise<void>>(
		() =>
			isOfficer && authOfficerVersion === '3'
				? import('@arv-bedrock/auth-sso').then(({ doRegister }) => doRegister(href))
				: import('@arv-bedrock/auth').then(({ doRegister }) => doRegister(href)),
		[href]
	)

	const contextValue = useMemo<IdentityContextValue>(
		() => ({
			...defaultValue,
			apiUrl: authUrlPeople,
			isOfficer,
			token,
			signIn,
			signOut,
			register,
		}),
		[token, isOfficer, signIn, signOut, register]
	)
	return <IdentityContext.Provider value={contextValue}>{children}</IdentityContext.Provider>
}

export function useIdentity() {
	return useContext(IdentityContext)
}
