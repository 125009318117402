import generateConfig from "rc-picker/lib/generate/moment";
import moment from "moment";

//#region override rc-picker
generateConfig.getYear = function getYear(date): number {
  const year = date.year();
  return date.locale() === "th" ? year + 543 : year;
};
generateConfig.setYear = function setYear(date, _year): moment.Moment {
  const clone = date?.clone?.();
  const year = clone?.locale() === "th" ? _year - 543 : _year;
  return clone?.year(year);
};

generateConfig.locale.format = function format(locale, date, _format) {
  var clone = date?.clone?.();
  var result = clone?.locale(locale);
  return result?.format(_format);
};

generateConfig.locale.parse = function parse(
  locale,
  text,
  formats
): moment.Moment | null {
  let date: moment.Moment | null = null;
  const valid = formats.some((format) => {
    date = moment(text, format, locale, true);
    if (locale === "th_TH") {
      const buddhistYear = date.year();
      const year = buddhistYear - 543;
      date = moment(
        text.replace(buddhistYear.toString(), year.toString()),
        format,
        locale,
        true
      );
    }
    return date?.isValid?.();
  });
  return valid ? date : null;
};

export default generateConfig;
//#endregion
