import { Tag } from 'antd'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import { IncStatus, statusConfig } from './incident'
import { useMicroservice } from '@/contexts/service'
import { GetLookupOutDto } from '@interface/dto/mis'

interface Props {
	statusId?: IncStatus | undefined
	label: string | undefined
	className?: string
}

const NotificationTag: FC<Props> = ({ statusId, label, className }: Props) => {
	const service = useMicroservice()
	const [statusList, setStatusList] = useState<GetLookupOutDto[]>([])

	useEffect(() => {
		service.mis.getLookupData('inc-status').then((res) => {
			setStatusList(res)
		})
	}, [])

	const color = useMemo(() => {
		if (statusId && statusList.length) {
			if (statusId === IncStatus.Approve) {
				return statusConfig[IncStatus.Wip].color
			} else {
				return statusConfig[statusId].color
			}
		} else {
			return '#A6A6B0'
		}
	}, [statusId, statusList])

	const newLabel = useMemo(() => {
		if (statusList.length) {
			if (statusId === IncStatus.Approve) {
				return statusList.find((item) => item.id === IncStatus.Wip)?.name
			} else {
				return statusList.find((item) => item.id === statusId)?.name
			}
		}
	}, [statusId, statusList])

	return (
		<Tag color={color} className={className}>
			{label || newLabel || ''}
		</Tag>
	)
}

export default memo(NotificationTag)
