import { ResponseDto } from '@interface/dto/common'
import {
	GetExtentMunicipalityAreaDtoIn,
	GetExtentMunicipalityAreaDtoOut,
	GetOrthoGroupInDto,
	GetOrthoGroupOutDto,
	IdentifyPinIncidentDtoIn,
	IdentifyPinIncidentDtoOut,
} from '@interface/dto/gis.dto'
import { send } from './microservice'

export function identifyPinIncident(payload: IdentifyPinIncidentDtoIn) {
	return send<ResponseDto<IdentifyPinIncidentDtoOut>, IdentifyPinIncidentDtoIn>('gis.municipality.check', payload)
}

export function getSbpGisOrtho(payload: GetOrthoGroupInDto) {
	return send<[GetOrthoGroupOutDto], GetOrthoGroupInDto>('gis.ortho-group.get', payload)
}

export function getExtentMunicipalityArea(payload: GetExtentMunicipalityAreaDtoIn) {
	return send<GetExtentMunicipalityAreaDtoOut[], GetExtentMunicipalityAreaDtoIn>(
		'gis.municipality-area.getExtent',
		payload
	)
}
