import * as mis from '@/api/mis'
import * as gis from '@/api/gis'
import * as file from '@/api/file'
import { send, emit, updateAccessToken, updateApiKey } from '@/api/microservice'
import { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CancelablePromise from 'cancelable-promise'
import { AxiosError } from 'axios'

export function useMicroservice() {
	return {
		gis,
		mis,
		file,
		send,
		emit,
		updateAccessToken(token?: string | void) {
			updateAccessToken(token)
		},
		updateApiKey(key?: string) {
			updateApiKey(key)
		},
	}
}

export function useUpdateAccessToken() {
	return function updateAccessToken(token?: string | void) {
		updateAccessToken(token)
	}
}

export function useUpdateApiKey() {
	return function updateApiKey(key?: string | void) {
		updateApiKey(key)
	}
}

export function useFetchQuery<TInput = any, TOutput = any>(
	deps: DependencyList,
	fetchMethod: (payload: TInput) => CancelablePromise<TOutput> | Promise<TOutput>,
	payload?: TInput,
	props?: {
		token?: string
		disabled?: (() => boolean) | boolean
		onSuccess?: (result: TOutput) => void
		onError?: (error: unknown) => void
	}
) {
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState<TOutput>()
	const [error, setError] = useState<AxiosError>()
	const promiseRef = useRef<CancelablePromise<TOutput> | Promise<TOutput>>()

	const disabled = useMemo(() => {
		if (props?.disabled === true) return true
		if (typeof props?.disabled === 'function' && props?.disabled() === true) return true
		return false
	}, [props?.disabled])

	const depList = Array.isArray(deps) ? [...deps, disabled] : [deps, disabled]

	const cancel = promiseRef.current && 'cancel' in promiseRef.current ? promiseRef.current.cancel : undefined

	const fetchData = useCallback(() => {
		setLoading(true)
		if (props?.token) {
			updateAccessToken(props?.token)
		}
		return fetchMethod(payload!)
			.then<TOutput>((res: TOutput) => {
				setData(res)
				props?.onSuccess?.(res)
				return res
			})
			.catch((err) => {
				setError(err)
				props?.onError?.(err)
				return err
			})
			.finally(() => setLoading(false), true)
	}, [fetchMethod, payload, props?.onError, props?.onSuccess, props?.token])

	useEffect(() => {
		if (disabled === true) return
		cancel?.()
		promiseRef.current = fetchData()
		return () => (promiseRef.current && 'cancel' in promiseRef.current ? promiseRef.current.cancel() : undefined)
	}, depList)

	const reload = useCallback(() => {
		cancel?.()
		return fetchData()
	}, [fetchData])

	const mutate = useCallback((data: TOutput) => {
		return setData(data)
	}, [])

	return useMemo(() => {
		return { data, error, loading, disabled, reload, mutate }
	}, [data, error, loading, disabled, reload, mutate])
}
