import { Badge, BadgeProps } from 'antd'
import classNames from 'classnames'

export default function BadgeNotification(props: BadgeProps) {
	const { className = '', ...allProps } = props
	return (
		<Badge
			className={classNames(
				'[&_.ant-badge-count]:top-[1px] [&_.ant-badge-count]:right-[2px] [&_.ant-badge-count]:h-[18px] [&_.ant-badge-count]:min-w-[18px] [&_.ant-badge-count]:shadow-none',
				className
			)}
			{...allProps}
		/>
	)
}
