import { Button as Component, ButtonProps } from 'antd'
import classNames from 'classnames'

export interface CustomButtonProps extends ButtonProps {
	textColor?: string
	borderColor?: string
}
export function Button(props: CustomButtonProps) {
	const { children, className = '', color, textColor, borderColor, style, ...allProps } = props
	return (
		<Component
			style={{
				color: textColor,
				borderColor: borderColor || color,
				backgroundColor: color,
				...style,
			}}
			{...allProps}
			className={classNames(
				{
					[`bg-[${color}]`]: !!color,
					[`hover:bg-[${color}]`]: !!color,
					[`focus:bg-[${color}]`]: !!color,
					[`active:bg-[${color}]`]: !!color,
					[`!text-${textColor}`]: !!textColor,
					[`!hover:text-${textColor}`]: !!textColor,
					[`!focus:text-${textColor}`]: !!textColor,
					[`!active:text-${textColor}`]: !!textColor,
					[`!border-${borderColor || color}`]: !!borderColor || !!color,
					[`hover:!border-${borderColor || color}`]: !!borderColor || !!color,
				},
				className
			)}
		>
			{children}
		</Component>
	)
}

Button.defaultProps = {} as ButtonProps

export function AccentButton(props: ButtonProps) {
	const { children, className = '', style, ...allProps } = props
	return (
		<Button
			className={`min-w-[100px] bg-[#01435F] text-white
      hover:border-[#01435F] hover:bg-[#016987] hover:text-white focus:bg-[#01435F] focus:text-white
      active:bg-[#01435F]
      ${className}`}
			{...allProps}
		>
			{children}
		</Button>
	)
}
