import { RcFile } from 'antd/lib/upload'
import { axiosInstance } from './microservice'

export async function uploadIncidentFile(file: string | RcFile | Blob) {
	const formData = new FormData()
	formData.append('file', file)
	return axiosInstance
		.post<void, any>('/incident-file', formData, {
			headers: { 'content-type': 'multipart/form-data' },
		})
		.then((res) => res.data)
}

export async function uploadUserFile(file: string | RcFile | Blob, userId: string) {
	const formData = new FormData()
	formData.append('file', file)
	formData.append('userId', userId)
	return axiosInstance
		.post<void, any>('/user-file', formData, {
			headers: { 'content-type': 'multipart/form-data' },
		})
		.then((res) => res.data)
}

export async function uploadResolveFile(file: string | RcFile | Blob) {
	const formData = new FormData()
	formData.append('file', file)
	return axiosInstance
		.post<void, any>('/resolve-file', formData, {
			headers: { 'content-type': 'multipart/form-data' },
		})
		.then((res) => res.data)
}
