import classNames from 'classnames'
import { PropsWithChildren } from 'react'

export interface PaperProps extends PropsWithChildren {
	className?: string
	rounded?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'none'
	border?: boolean
	bgColor?: string
}

export function Paper(props: PaperProps) {
	const { children, className = '', rounded = 'lg', border = true, bgColor = 'white' } = props
	const borderStyle = border ? 'border border-default' : ''
	return (
		<div
			style={{ backgroundColor: bgColor }}
			className={classNames(
				borderStyle,
				{
					[`rounded-${rounded}`]: !!rounded,
					[`bg-${bgColor}`]: !!bgColor,
				},
				className
			)}
		>
			{children}
		</div>
	)
}
