export enum IncStatus {
	Send = 1,
	Wip,
	Approve,
	Confirm,
	Close,
	Pending,
	Cancel,
	Resolved, // Alert!! เป็นสถานะที่ใช้เฉพาะตอนเจ้าหน้าที่ทำงาน ไม่ได้เป็นสถานะของใบแจ้งเหตุ
}

export const statusConfig = {
	[IncStatus.Send]: { name: 'รอรับเรื่อง', color: '#FAAF3A', order: 1 },
	[IncStatus.Wip]: { name: 'กำลังดำเนินการ', color: '#00AA86', order: 2 },
	[IncStatus.Approve]: { name: 'รออนุมัติ', color: '#F27D3B', order: 3 },
	[IncStatus.Confirm]: { name: 'ยืนยันการแก้ปัญหา', color: '#9D6001', order: 4 },
	[IncStatus.Close]: { name: 'ดำเนินการแล้วเสร็จ', color: '#737373', order: 5 },
	[IncStatus.Pending]: { name: 'ชะลอ', color: '#0080C3', order: 6 },
	[IncStatus.Cancel]: { name: 'ถูกยกเลิก', color: '#C88480', order: 7 },
	[IncStatus.Resolved]: { name: 'ดำเนินการแล้ว', color: '#737373', order: 8 }, // Alert!! เป็นสถานะที่ใช้เฉพาะตอนเจ้าหน้าที่ทำงาน ไม่ได้เป็นสถานะของใบแจ้งเหตุ
}
