import { OfficerRoles } from '@interface/config/am.config'
import { UserRole } from '@interface/dto/am.dto'
import { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react'

import type { UserContextFunction, UserContextProps, UserContextValue } from './types'

const defaultValue: UserContextValue = {
	isOfficer: false,
	profile: undefined,
	signedIn: undefined,
	activeRole: undefined,
	activeOfficerRole: undefined,
}

export const UserContext = createContext<UserContextProps>({
	...defaultValue,
	set() {},
	unset() {},
	setActiveRole() {},
	setActiveOfficerRole() {},
	getRoleName() {
		return ''
	},
})

export interface UserProviderProps extends PropsWithChildren {
	isOfficer?: boolean
}

export function UserProvider({ isOfficer = false, children }: UserProviderProps) {
	const [profile, setProfile] = useState<NonNullable<UserContextValue['profile']>>()
	const [signedIn, setSignedIn] = useState<boolean>()
	const [activeRole, setActiveRole] = useState<UserRole>(null)
	const [activeOfficerRole, setActiveOfficerRoleState] = useState<OfficerRoles>()

	const setActiveOfficerRole = useCallback((role?: OfficerRoles) => {
		if (role) {
			setActiveOfficerRoleState(role)
			localStorage.setItem('activeRole', role.toString())
		} else {
			setActiveOfficerRoleState(undefined)
			localStorage.removeItem('activeRole')
		}
	}, [])

	const getRoleName = useCallback<UserContextFunction['getRoleName']>(
		(role) => {
			switch (role) {
				case 'officer':
					return 'เจ้าหน้าที่'
				case 'people':
					return 'ผู้ใช้งานทั่วไป'
				default:
					return 'Anonymous'
			}
		},
		[activeRole, activeOfficerRole]
	)

	const initSet = useCallback((profile: NonNullable<UserContextValue['profile']>) => {
		setSignedIn(true)
		setActiveRole(profile.role)
		if (profile.role === 'officer') {
			const lastActiveRole: unknown = localStorage.getItem('activeRole')
			setActiveOfficerRoleState(
				typeof lastActiveRole !== 'string'
					? profile.officerRoles[0]
					: (parseInt(lastActiveRole) as OfficerRoles)
			)
		}
		setProfile(profile)
	}, [])
	const initUnset = useCallback(() => {
		setProfile(defaultValue.profile)
		setSignedIn(false)
		setActiveRole(null)
		setActiveOfficerRole(undefined)
	}, [])
	const contextValue = useMemo<UserContextProps>(() => {
		return {
			...defaultValue,
			isOfficer,
			signedIn,
			profile,
			activeRole,
			activeOfficerRole,
			getRoleName,
			set: initSet,
			unset: initUnset,
			setActiveRole,
			setActiveOfficerRole,
		}
	}, [
		isOfficer,
		signedIn,
		profile,
		activeRole,
		activeOfficerRole,
		getRoleName,
		initSet,
		initUnset,
		setActiveRole,
		setActiveOfficerRole,
	])

	return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}
