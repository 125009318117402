import { mdiCheck, mdiCheckboxBlankCircle, mdiClose } from '@mdi/js'
import Icon, { Stack } from '@mdi/react'
import { Modal as Component, ModalFuncProps, ModalProps } from 'antd'
import classNames from 'classnames'

export function Modal(props: ModalProps) {
	const { centered = true, children, className, wrapClassName, ...allProps } = props
	return (
		<Component
			{...allProps}
			centered={false}
			wrapClassName={classNames(
				'overflow-hidden my-8',
				{ 'flex flex-col items-center justify-center': centered },
				wrapClassName
			)}
			className={classNames(
				'top-auto max-h-full pb-0',
				'[&_.ant-modal-content]:flex [&_.ant-modal-content]:max-h-full [&_.ant-modal-content]:flex-col',
				'[&_.ant-modal-body]:flex [&_.ant-modal-body]:flex-1 [&_.ant-modal-body]:flex-col [&_.ant-modal-body]:overflow-hidden',
				className
			)}
		>
			{children}
		</Component>
	)
}

Modal.error = async (props?: ModalFuncProps & { showOk?: boolean }) => {
	return new Promise((resolve, reject) => {
		const showOk = props?.showOk ?? false
		const { destroy, update } = Component.error({
			autoFocusButton: null,
			okCancel: showOk,
			okText: showOk ? 'ดำเนินการต่อ' : 'ย้อนกลับ',
			okButtonProps: { type: showOk ? 'primary' : 'ghost' },
			cancelText: 'ย้อนกลับ',
			width: 'auto',
			icon: (
				<Stack size={2} className='text-error'>
					<Icon path={mdiCheckboxBlankCircle} className='opacity-20' />
					<Icon size={1} path={mdiClose} />
				</Stack>
			),
			...props,
		})
		update({
			onCancel: () => {
				destroy()
				resolve(false)
			},
			onOk: () => {
				destroy()
				resolve(showOk)
			},
		})
	})
}

Modal.infoNoButton = (props?: ModalFuncProps) => {
	return Component.info({
		autoFocusButton: null,
		okCancel: false,
		okButtonProps: { className: 'hidden' },
		closable: true,
		icon: null,
		width: 480,
		...props,
	})
}

Modal.confirm = async (props?: ModalFuncProps) => {
	const { title = 'ข้อความจากระบบ', ...allProps } = props || {}
	return new Promise<boolean>((resolve, reject) => {
		const { destroy, update } = Component.confirm({
			title,
			width: 480,
			cancelText: 'ย้อนกลับ',
			okText: 'ยืนยัน',
			...allProps,
		})
		update({
			onCancel: () => {
				destroy()
				resolve(false)
				props?.onCancel?.()
			},
			onOk: () => {
				destroy()
				resolve(true)
				props?.onOk?.()
			},
		})
	})
}

Modal.success = (props?: ModalFuncProps) => {
	return Component.success({
		icon: (
			<Stack size={2} className='text-success'>
				<Icon path={mdiCheckboxBlankCircle} className='opacity-20' />
				<Icon size={1} path={mdiCheck} />
			</Stack>
		),
		width: 480,
		...props,
	})
}

Modal.delete = async (message?: string, props?: ModalFuncProps) => {
	const okColor = '#E53E3E'
	const cancelColor = '#E2E8F0'
	return new Promise((resolve, reject) => {
		const { destroy, update } = Component.confirm({
			className: 'modal-2',
			title: 'ยืนยันการลบข้อมูล',
			content: message || 'คุณแน่ใจใช่ไหม ? หากคุณยืนยัน คุณจะไม่สามารถย้อนกลับได้อีก',
			okCancel: true,
			cancelText: 'ยกเลิก',
			okText: 'ยืนยัน',
			okButtonProps: {
				className: 'btn-delete',
			},
			cancelButtonProps: {
				className: 'btn-cancel',
			},
			icon: false,
			...props,
		})
		update({
			onCancel: () => {
				destroy()
				resolve(false)
			},
			onOk: () => {
				destroy()
				resolve(true)
			},
		})
	})
}

Component.defaultProps = {
	centered: true,
	cancelText: 'ย้อนกลับ',
	okText: 'ย้อนกลับ',
	cancelButtonProps: {
		type: 'ghost',
	},
}
