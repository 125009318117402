import moment from 'moment'
import '@/utils/th-buddhist-date'
import '@/styles/root.less'
import '@/styles/tailwind.css'
import 'moment/locale/th'
import '@/utils/antd-default-props'
import type { AppContext, AppProps } from 'next/app'
import ConfigProvider from 'antd/lib/config-provider'
import { UserProvider } from '@/contexts/user/context'
import { AppProvider } from '@/contexts/app/context'
import { LineProvider } from '@/contexts/line/context'
import thTH from 'antd/lib/locale/th_TH'
import MainLayout from '@/components/layout/Main'
import getConfig from 'next/config'
import { IdentityProvider as BedrockIdentityProvider } from '@/modules/arv/identity'
import Script from 'next/script'

const { publicRuntimeConfig } = getConfig()
const { officerOrigin, vconsole, ga } = publicRuntimeConfig

moment.locale('th')
declare global {
	interface Window {
		dataLayer: Record<string, any>[]
	}
}

export default function MyApp({ Component, pageProps }: AppProps<{ isOfficer: boolean; appUrl: string }>) {
	return (
		<>
			<Script
				strategy='lazyOnload'
				src={`https://www.googletagmanager.com/gtag/js?id=${
					pageProps.isOfficer ? ga.gaMeasurementIdOfficer : ga.gaMeasurementIdPeople
				}`}
			/>

			<Script strategy='lazyOnload'>
				{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${pageProps.isOfficer ? ga.gaMeasurementIdOfficer : ga.gaMeasurementIdPeople}', {
          page_path: window.location.pathname,
          });
        `}
			</Script>
			<Script id='google-tag-manager' strategy='afterInteractive'>
				{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${
			pageProps.isOfficer ? ga.gtmContainerIdOfficer : ga.gtmContainerIdPeople
		}');
      `}
			</Script>
			<AppProvider appUrl={pageProps.appUrl} isOfficer={pageProps.isOfficer}>
				<LineProvider isOfficer={pageProps.isOfficer}>
					<UserProvider isOfficer={pageProps.isOfficer}>
						<BedrockIdentityProvider isOfficer={pageProps.isOfficer}>
							<ConfigProvider locale={thTH}>
								<MainLayout>
									<Component {...pageProps} />
								</MainLayout>
							</ConfigProvider>
						</BedrockIdentityProvider>
					</UserProvider>
				</LineProvider>
			</AppProvider>
			<Script
				src='https://unpkg.com/vconsole@latest/dist/vconsole.min.js'
				onLoad={() => {
					if (vconsole === 'true') {
						new (window as any).VConsole()
					}
				}}
			/>
		</>
	)
}

MyApp.getInitialProps = (app: AppContext) => {
	let appUrl = ''
	let isOfficer = false
	if (officerOrigin) {
		if (typeof location === 'undefined') {
			const url = new URL(`https://${app.ctx.req?.headers.host}`)
			isOfficer = url.hostname === officerOrigin
			appUrl = url.href
		} else {
			const url = new URL(location.href)
			isOfficer = url.hostname === officerOrigin
			appUrl = url.href
		}
	}

	return {
		pageProps: {
			...app.Component?.getInitialProps?.(app.ctx),
			appUrl,
			isOfficer,
		},
	}
}
