import {
	mdiAccountCogOutline,
	mdiAccountHardHatOutline,
	mdiAccountMultipleOutline,
	mdiBallotOutline,
	mdiCogOutline,
	mdiHomeOutline,
	mdiListBoxOutline,
	mdiPencilOutline,
	mdiChartBoxPlusOutline,
	mdiClipboardCheck,
	mdiAccountClock,
	mdiClockTimeFourOutline,
	mdiMagnify,
} from '@mdi/js'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const { apiUrl } = publicRuntimeConfig

export const getImageUrl = (fileId: string, thumbnail = false, blur = false): string => {
	if (fileId) {
		if (blur) {
			return `${apiUrl}/incident-file/download?id=${fileId}&blur=true`
		} else if (thumbnail) {
			return `${apiUrl}/incident-file/download?id=${fileId}&thumbnail=true`
		} else {
			return `${apiUrl}/incident-file/download?id=${fileId}`
		}
	} else {
		return ''
	}
}

export const getResolveImageUrl = (fileId: string): string => {
	return fileId ? `${apiUrl}/resolve-file/download?id=${fileId}` : ''
}

export const videoIcon =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAOBJREFUSEvtVDEOwiAUfdRE13oS9QTqEVxkdsMT6da5U48g3kBv4loTwVCkgdgImNKpbJDHe+8/Pp8g8SKJ+TEKeBMeNqJJeeISZO21ZQEkAZd7tm2PqiLH7vAwe6eCrDzLGHKDFZRpnqrIs2d9EZStfgq0FzxqxlCD/5ADWNr3OyuIFpjO5sq5IleeehcAcDPkqQScMFNUkFzgDmDx1VWAO4ucrgjo1xavH5kbkd4jstq0EfEKBJjvzlz/BS4oa9pVLecf/DMqCOT1RY+boFER6zwEP+w0DXEUixkr8Cb2BhymfhlwtHvZAAAAAElFTkSuQmCC'

export const uploadConfig = {
	image: 25,
	video: 25,
}

export const statusCancel = 4

export enum BellMeMenu {
	Overview = '/p/overview',
	IncidentSearch = '/p/incident-search',
	MyIncident = '/p/my-incident',
	Incident = '/p/incident',
	Inform = '/p/inform',
	Manage = '/o/manage-incident',
	Config = '/o/config',
	DivisionConfig = '/o/config/division',
	DivisionConfigForm = '/o/config/division/form',
	ProblemConfig = '/o/config/problem',
	ProblemConfigForm = '/o/config/problem/form',
	ProfileOfficer = '/o/profile',
	ProfilePeople = '/p/profile',
	UM = '/u',
	ReportOperationPerson = '/o/report/operation-person',
	ReportOperationProblem = '/o/report/operation-problem',
	ReportDurationDivision = '/o/report/duration-division',
	ReportDurationProblem = '/o/report/duration-problem',
	Protal = '/p/portal',
	Setting = '/o/config/settings',
}

export const allMenu = [
	BellMeMenu.Overview,
	BellMeMenu.IncidentSearch,
	BellMeMenu.MyIncident,
	BellMeMenu.Incident,
	BellMeMenu.Inform,
	BellMeMenu.Manage,
	BellMeMenu.Config,
	BellMeMenu.DivisionConfig,
	BellMeMenu.ProblemConfig,
	BellMeMenu.ProfileOfficer,
	BellMeMenu.ProfilePeople,
	BellMeMenu.UM,
	BellMeMenu.Protal,
	BellMeMenu.ReportOperationPerson,
	BellMeMenu.ReportOperationProblem,
	BellMeMenu.ReportDurationDivision,
	BellMeMenu.ReportDurationProblem,
	BellMeMenu.Setting,
]

export const menuConfig = {
	[BellMeMenu.Overview]: {
		icon: mdiHomeOutline,
		title: 'ภาพรวมการแจ้งเหตุ',
	},
	[BellMeMenu.IncidentSearch]: {
		icon: mdiMagnify,
		title: 'ติดตาม/ค้นหาสถานะใบแจ้ง',
	},
	[BellMeMenu.MyIncident]: {
		icon: mdiBallotOutline,
		title: 'รายการของฉัน',
	},
	[BellMeMenu.Incident]: {
		icon: null,
		title: 'รายละเอียดการแจ้งเหตุ',
	},
	[BellMeMenu.Inform]: {
		icon: null,
		title: 'แจ้งเหตุ/ร้องเรียนใหม่',
	},
	[BellMeMenu.Config]: {
		icon: mdiAccountHardHatOutline,
		title: 'กำหนดผู้รับผิดชอบงาน',
	},
	[BellMeMenu.Manage]: {
		icon: mdiBallotOutline,
		title: 'จัดการปัญหา/ร้องเรียน',
	},
	[BellMeMenu.UM]: {
		icon: mdiAccountMultipleOutline,
		title: 'จัดการข้อมูลผู้ใช้งาน',
	},
	[BellMeMenu.ProfileOfficer]: {
		icon: mdiPencilOutline,
		title: 'ข้อมูลส่วนตัว',
	},
	[BellMeMenu.ProfilePeople]: {
		icon: mdiPencilOutline,
		title: 'ข้อมูลส่วนตัว',
	},
	[BellMeMenu.Protal]: {
		icon: null,
		title: 'ระบบการร้องเรียนแจ้งปัญหา',
	},
	[BellMeMenu.DivisionConfig]: {
		icon: mdiAccountCogOutline,
		title: 'การจัดการหน่วยงาน',
	},
	[BellMeMenu.DivisionConfigForm]: {
		icon: mdiAccountCogOutline,
		title: 'การจัดการหน่วยงาน',
	},
	[BellMeMenu.ProblemConfig]: {
		icon: mdiListBoxOutline,
		title: 'การจัดการประเภทปัญหา',
	},
	[BellMeMenu.ProblemConfigForm]: {
		icon: mdiListBoxOutline,
		title: 'การจัดการประเภทปัญหา',
	},
	[BellMeMenu.Setting]: {
		icon: mdiCogOutline,
		title: 'ตั้งค่าระบบ',
	},
	[BellMeMenu.ReportOperationPerson]: {
		icon: mdiChartBoxPlusOutline,
		title: 'สรุปผลการปฏิบัติงานของเจ้าหน้าที่',
	},
	[BellMeMenu.ReportOperationProblem]: {
		icon: mdiClipboardCheck,
		title: 'สรุปผลการปฏิบัติงานตามประเภทปัญหา',
	},
	[BellMeMenu.ReportDurationDivision]: {
		icon: mdiAccountClock,
		title: 'ระยะเวลาที่ใช้แก้ปัญหาของแต่ละกอง',
	},
	[BellMeMenu.ReportDurationProblem]: {
		icon: mdiClockTimeFourOutline,
		title: 'ระยะเวลาที่ใช้แก้ไขปัญหาของแต่ละปัญหา',
	},
}
