import { LineProfile, useUser } from '@/contexts/user'
import { Avatar, Button, Menu, Popover } from 'antd'
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Icon from '@mdi/react'
import { mdiMenuDown, mdiPencilOutline, mdiLogout, mdiLink, mdiLinkOff } from '@mdi/js'

import { sleep } from '@/utils/sleep'
import { useRouter } from 'next/router'
import { useApp } from '@/contexts/app'
import { MenuItemType } from 'antd/lib/menu/hooks/useItems'
import * as jwt from 'jsonwebtoken'
import { useMicroservice } from '@/contexts/service'
import { Modal } from '../modal/Modal'
import lodash from 'lodash'

import { useIdentity } from '@/modules/arv/identity'
import { BellMeMenu } from '@/utils/incident'
import { LineOaEntity } from '@interface/entities'
import { LineContext } from '@/contexts/line/context'
import getConfig from 'next/config'
import { gaEvent } from '@/utils/google-analytic'
const { publicRuntimeConfig } = getConfig()
const { apiUrl } = publicRuntimeConfig

export default function AccountMenuButton({
	className,
	showPopover = true,
}: {
	className?: string
	showPopover?: boolean
}) {
	return (
		<div className={`flex items-center ${className}`}>
			{showPopover ? (
				<Popover content={<AccountMenuContent />} trigger='click' placement='bottomRight'>
					<Button
						className='min-h-[48px] min-w-[128px] max-w-[240px] overflow-hidden rounded-full bg-primary p-0 hover:bg-primary  focus:bg-primary active:bg-primary'
						onClick={() => {
							window.dataLayer.push({ event: gaEvent.navbarClickMenuProfile })
						}}
					>
						<AccountTitle showPopover={showPopover} />
					</Button>
				</Popover>
			) : (
				<Button
					className={`min-h-[48px] min-w-[128px] max-w-[240px] overflow-hidden rounded-full ${
						showPopover ? 'bg-primary p-0 hover:bg-primary  focus:bg-primary active:bg-primary' : ''
					}`}
					onClick={() => {
						window.dataLayer.push({ event: gaEvent.navbarClickMenuProfile })
					}}
				>
					<AccountTitle showPopover={showPopover} />
				</Button>
			)}
		</div>
	)
}

const AccountTitle: FC<{ showPopover: boolean }> = ({ showPopover }: { showPopover: boolean }) => {
	const user = useUser()
	const service = useMicroservice()
	const profile = user.profile
	const [profileUrl, setProfileUrl] = useState<string>()

	useEffect(() => {
		if (profile) {
			service.mis.getUserFile().then((resFile) => {
				if (resFile) {
					setProfileUrl(`${apiUrl}/user-file/download?id=${resFile.fileId}&thumbnail=true`)
				} else {
					setProfileUrl(profile?.picture)
				}
			})
		}
	}, [profile])

	return (
		<div className='flex items-center '>
			{!profileUrl ? (
				<Avatar className='ml-1 h-[40px] w-[40px] min-w-[40px] text-lg leading-10'>
					{profile?.name?.slice(0, 1).toLocaleUpperCase()}
				</Avatar>
			) : (
				<Avatar src={profileUrl} className='ml-1 h-[40px] w-[40px] min-w-[40px] text-lg leading-10' />
			)}
			<div className='ml-3 overflow-hidden text-left'>
				<div
					className={`overflow-hidden text-ellipsis text-sm font-semibold text-text-primary ${
						showPopover ? 'text-white' : ''
					}`}
				>
					{profile?.name && profile?.surname ? `${profile?.name} ${profile?.surname}` : `${profile?.email}`}
				</div>
				<div
					className={`overflow-hidden text-ellipsis text-xs text-text-secondary ${
						showPopover ? 'text-white' : ''
					}`}
				>
					{user.profile?.role === 'officer' ? user.profile.officerAreaName || '' : 'ประชาชน'}
				</div>
			</div>
			{showPopover && (
				<div className='flex-1 px-4 text-text-secondary text-white'>
					<Icon size={0.75} path={mdiMenuDown} />
				</div>
			)}
		</div>
	)
}

const AccountMenuContent: FC = () => {
	const { liffLogin, liffLogout, detectConnect, detectDisconnect } = useContext(LineContext)
	const { setShowSplashScreen } = useApp()

	const [lineOA, setLineOA] = useState<LineOaEntity>()
	const [isLineConnect, setIsLineConnect] = useState<boolean>(false)
	const [lineProfile, setLineProfile] = useState<LineProfile>()

	const user = useUser()
	const identity = useIdentity()
	const router = useRouter()
	const service = useMicroservice()

	useEffect(() => {
		if (user.profile) {
			service.mis.getDefaultLineOA().then(async (res) => {
				const lineOA = res?.data
				const result = await service.mis.getLineAuth({ lineOAId: lineOA?.id })
				if (result?.data?.lineToken) {
					const decode = jwt.decode(result.data.lineToken)
					setLineProfile(decode as LineProfile)
					setIsLineConnect(true)
				} else {
					setIsLineConnect(false)
				}
				setLineOA(lineOA)
			})
		}
	}, [user.profile, detectConnect, detectDisconnect])

	const signOut = useCallback(() => {
		window.dataLayer.push({ event: gaEvent.navbarClickSubmenuLogOutInProfile })
		setShowSplashScreen(true)
		user.unset()
		identity
			.signOut()
			.then(() => sleep(300))
			.then(() => router.push(BellMeMenu.Overview))
			.then(() => sleep(300))
			.then(() => setShowSplashScreen(false))
	}, [identity, user, router])

	const roles = useMemo(() => (user.profile?.role === 'officer' ? user.profile!.officerRoles : []), [user.profile])

	const menuItems = useMemo(() => {
		let items = [
			{
				icon: <Icon size={0.75} path={isLineConnect ? mdiLinkOff : mdiLink} />,
				disabled: !lineOA,
				label: isLineConnect ? (
					<>
						ยกเลิก LINE : <span className='text-secondary'>{lineProfile?.name}</span>
					</>
				) : (
					`เชื่อมต่อบัญชีไลน์ ${
						lineOA?.muni ? `(${lineOA?.muni?.name})` : lineOA?.lineOaName ? `(${lineOA?.lineOaName})` : ''
					}`
				),
				key: '4',
				onClick: async () => {
					window.dataLayer.push({ event: gaEvent.navbarClickSubmenuConnectLineInProfile })

					if (isLineConnect) {
						const isConfirm = await Modal.confirm({
							content: (
								<>
									ท่านยืนยันการยกการเชื่อมต่อบัญชี LINE ของท่านใช่หรือไม่ หากท่านยืนยัน
									ท่านจะไม่สามารถเข้าผ่าน LINE Official :{' '}
									<span
										className='cursor-pointer text-secondary hover:underline'
										onClick={() => window.open(`https://line.me/R/ti/p/${lineOA?.lineOaBasicId}`)}
									>
										{lineOA?.lineOaName}
									</span>{' '}
									เพื่อติดตามสถานะ การแจ้งเหตุของท่านได้
								</>
							),
						})
						if (!isConfirm) return
						if (lineOA?.id) {
							const liffId = user.isOfficer ? lineOA.lineLoginLiffIdOfficer : lineOA.lineLoginLiffIdPeople
							liffLogout(liffId as string, lineOA?.id)
						}
					} else {
						if (lineOA?.id) {
							const liffId = user.isOfficer ? lineOA.lineLoginLiffIdOfficer : lineOA.lineLoginLiffIdPeople
							liffLogin(liffId as string)
						}
					}
				},
			},
			{ type: 'divider' },
			{
				icon: <Icon size={0.75} path={mdiLogout} />,
				label: 'ออกจากระบบ',
				key: '3',
				onClick: signOut,
			},
		] as MenuItemType[]

		items.unshift({
			icon: <Icon size={0.75} path={mdiPencilOutline} />,
			label: 'ข้อมูลส่วนตัว',
			key: '2',
			onClick: () => {
				window.dataLayer.push({ event: gaEvent.navbarClickSubmenuMyProfileInProfile })

				router.push(user.profile?.role === 'officer' ? BellMeMenu.ProfileOfficer : BellMeMenu.ProfilePeople)
			},
		})

		items = items.map<MenuItemType>((item: any) => {
			return {
				...item,
				className: item.type !== 'divider' ? 'p-0 text-text-secondary hover:text-primary' : 'py-2 ml-4',
			}
		})
		return items
	}, [identity.apiUrl, roles, user.activeRole, user.activeOfficerRole, lineProfile, lineOA])

	return (
		<Menu
			expandIcon={false}
			openKeys={['1']}
			selectable={false}
			mode='inline'
			className='group w-72'
			inlineIndent={16}
			items={menuItems}
		/>
	)
}
