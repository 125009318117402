import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Layout, Typography, Menu, Drawer, Divider } from 'antd'
import { useUser } from '@/contexts/user'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useIdentity } from '@/modules/arv/identity'
import { useRouter } from 'next/router'
import Icon from '@mdi/react'
import {
	mdiHomeOutline,
	mdiMenu,
	mdiBallotOutline,
	mdiLogout,
	mdiArrowLeft,
	mdiAccountMultipleOutline,
	mdiAccountHardHatOutline,
	mdiListBoxOutline,
	mdiAccountCogOutline,
	mdiCogBox,
	mdiClose,
	mdiPencilOutline,
	mdiCogOutline,
	mdiChartBoxPlusOutline,
	mdiClipboardCheck,
	mdiClockTimeFourOutline,
	mdiAccountClock,
	mdiMagnify,
} from '@mdi/js'
import AppLogo from './svg/AppLogo'
import BellNotificationDesktop from './svg/BellNotificationDesktop'
import BellNotificationMobile from './svg/BellNotificationMobile'
import AccountMenuButton from './common/button/AccountMenuButton'
import BadgeNotification from './common/badge/Badge'
import { Notification } from './pages/people/notification/Notification'
import { useApp, useResponsive } from '@/contexts/app'
import { sleep } from '@/utils/sleep'
import { useMicroservice } from '@/contexts/service'
import { Modal } from './common/modal/Modal'
import Body from './common/text/Body'
import { BellMeMenu, allMenu, menuConfig } from '@/utils/incident'
import { OfficerUserLevel, OfficerRoles } from '@interface/config/am.config'
import { Modal as ModalAntd } from 'antd'
import { LineContext } from '@/contexts/line/context'
import getConfig from 'next/config'
import { gaEvent } from '@/utils/google-analytic'
import { useSocket } from '@/modules/socket.io/hook'

const { publicRuntimeConfig: config } = getConfig()

const { Header } = Layout

interface IProps {
	title: string
	subtitle?: string
}

export default function AppBar({ title, subtitle }: IProps) {
	const { signedIn, activeRole, profile } = useUser()
	const { signIn, signOut, register } = useIdentity()
	const { liffObject, lineOA, defaultLiffObject, defaultLineOA, saveLineToken } = useContext(LineContext)
	const user = useUser()
	const socket = useSocket()
	const {
		setShowSplashScreen,
		subPage,
		setSubPage,
		openNotification,
		setOpenNotification,
		notificationCount,
		setNotificationCount,
	} = useApp()
	const router = useRouter()
	const screen = useResponsive()
	const service = useMicroservice()
	const [openDrawer, setOpenDrawer] = useState(false)
	const [loading, setLoading] = useState(false)
	const [onOpenSubMenu, setOnOpenSubMenu] = useState(false)
	const [officerAreaId, setOfficerAreaId] = useState('')
	const notiRef = useRef<HTMLDivElement>(null)
	const { isDesktop } = useResponsive()
	const [isClient, setIsClient] = useState(false)

	useEffect(() => {
		setIsClient(true)
	}, [])

	useEffect(() => {
		if (openDrawer && screen.isDesktop) {
			setOpenDrawer(false)
		}
	}, [openDrawer, screen.breakpoint, screen.isDesktop])

	useEffect(() => {
		if (screen.isDesktop) {
			setSubPage(null)
		}
	}, [screen.breakpoint, screen.isDesktop])

	useEffect(() => {
		if (profile) {
			fetchNotificationCount()
		}
	}, [profile, router.pathname])

	const successModal = () => {
		return Modal.success({
			title: 'เชื่อมต่อผ่าน Line เรียบร้อยแล้ว',
			okText: 'ปิด',
			cancelText: 'หน้าหลัก',
			okCancel: true,
			onOk() {},
			onCancel() {
				router.push(BellMeMenu.Overview)
			},
			content: (
				<>
					<Body className='mb-4'>
						ระบบทำการเชื่อมต่อข้อมูลกับ Line ของท่านแล้ว ท่านสามารถเข้าผ่าน{' '}
						<div>
							LINE Official :{' '}
							<span
								className='cursor-pointer text-secondary hover:underline'
								onClick={() => window.open(`https://line.me/R/ti/p/${lineOA?.lineOaBasicId}`)}
							>
								{lineOA?.lineOaName}
							</span>
						</div>
						<div>
							ท่านจะได้รับการแจ้งเตือนผ่าน Line ที่เชื่อมต่อไว้ และสามารถทำรายการต่างๆ เช่น การแจ้งเหตุ
							หรือ ติดตามสถานะการแจ้งเหตุผ่าน Line ได้
						</div>
					</Body>
				</>
			),
		})
	}
	const duplicateModal = () => {
		Modal.error({
			title: 'เชื่อมต่อผ่าน Line ไม่สำเร็จ',
			okText: 'ปิด',
			okButtonProps: {
				onClick: () => {
					ModalAntd.destroyAll()
				},
			},
			content: (
				<>
					<div>พบบัญชี LINE ในระบบ </div>
					<div>กรุณายกเลิกการเชื่อมต่อจากบัญชีเดิมเพื่อเชื่อมต่อใหม่</div>
				</>
			),
		})
	}
	const failModal = () => {
		Modal.error({
			title: 'เชื่อมต่อผ่าน Line ไม่สำเร็จ',
			okText: 'ปิด',
			okButtonProps: {
				onClick: () => {
					ModalAntd.destroyAll()
					window.location.reload()
				},
			},
			content: (
				<>
					<div>พบปัญหาการเชื่อมต่อ โปรดลองใหม่อีกครั้ง</div>
				</>
			),
		})
	}

	useEffect(() => {
		if (profile && profile.isOfficer) {
			service.mis.getOfficer().then((res) => {
				if (res && res.officerAreaId) {
					setOfficerAreaId(res.officerAreaId)
				}
			})
		}
	}, [profile])

	useEffect(() => {
		// console.log('---------------App Bar----------------')
		// console.log('######## liffObject?.liff?.id', liffObject?.liff?.id)
		// console.log('######## liffObject?.liff?.isLoggedIn() : ', liffObject?.liff?.isLoggedIn())
		// console.log('######## lineOA?.id', lineOA?.id)
		const liff = liffObject?.liff
		if (profile && liff && !liff.isInClient()) {
			const liffId = sessionStorage?.getItem('liff-login-id')
			if (liffId && liffId === liff.id) {
				sessionStorage.removeItem('liff-login-id')
				if (liff.isLoggedIn()) {
					saveLineToken(lineOA?.id as string).then((success) => {
						if (success) {
							successModal()
						} else {
							liff.logout()
							duplicateModal()
						}
					})
				} else {
					failModal()
				}
			}
		}
	}, [liffObject, lineOA, profile])

	const fetchNotificationCount = () => {
		service.mis.getIncidentNotificationCount().then((data) => {
			setNotificationCount(data?.count ? data.count : 0)
		})
	}

	const onClickSignOut = useCallback(async () => {
		setShowSplashScreen(true)
		user.unset()
		const liff = defaultLiffObject?.liff
		if (liff?.isInClient()) {
			localStorage.removeItem('bedrockAccessToken')
			localStorage.removeItem('bedrockRefreshToken')
			await sleep(300)
			await service.mis.disconnectLine({ lineOAId: defaultLiffObject?.lineOAId })
			signOut()
				.then(() => sleep(300))
				.then(() => liff?.logout())
				.then(() => router.push(BellMeMenu.Protal))
				.then(() => sleep(300))
				.then(() => setShowSplashScreen(false))
		} else {
			signOut()
				.then(() => sleep(300))
				.then(() => liff?.logout())
				.then(() => router.push(BellMeMenu.Overview))
				.then(() => sleep(300))
				.then(() => setShowSplashScreen(false))
		}
	}, [user, router, defaultLiffObject])

	const menuItems = useMemo<ItemType[]>(() => {
		const items: ItemType[] = []
		items.push({
			key: BellMeMenu.Overview,
			label: 'ภาพรวมการแจ้งเหตุ',
			icon: <Icon size={1} path={mdiHomeOutline} />,
		})
		if (activeRole === 'officer' && profile?.role === 'officer') {
			items.push({
				key: '/o/manage-incident',
				label: 'จัดการปัญหา/ร้องเรียน',
				icon: <Icon size={1} path={mdiBallotOutline} />,
			})
		}
		items.push({
			key: BellMeMenu.IncidentSearch,
			label: 'ติดตาม/ค้นหาสถานะใบแจ้ง',
			icon: <Icon size={1} path={mdiMagnify} />,
		})
		if (activeRole === 'people') {
			items.push({
				key: BellMeMenu.MyIncident,
				label: 'รายการของฉัน',
				icon: <Icon size={1} path={mdiBallotOutline} />,
			})
		} else if (activeRole === 'officer') {
			if (profile?.role === 'officer') {
				if (profile.userLevel === OfficerUserLevel.SuperAdmin || profile.userLevel === OfficerUserLevel.Admin) {
					items.push({
						key: '/u',
						label: 'จัดการข้อมูลผู้ใช้งาน',
						icon: <Icon size={1} path={mdiAccountMultipleOutline} />,
					})

					items.push({
						key: 'config',
						label: 'การจัดการระบบ',
						icon: <Icon size={1} path={mdiCogBox} />,
						children: [
							{
								key: BellMeMenu.DivisionConfig,
								label: 'การจัดการหน่วยงาน',
								icon: <Icon size={1} path={mdiAccountCogOutline} />,
							},
							{
								key: BellMeMenu.ProblemConfig,
								label: 'การจัดการประเภทปัญหา',
								icon: <Icon size={1} path={mdiListBoxOutline} />,
							},
							{
								key: BellMeMenu.Config,
								label: 'กำหนดผู้รับผิดชอบ',
								icon: <Icon size={1} path={mdiAccountHardHatOutline} />,
							},
							{
								key: BellMeMenu.Setting,
								label: 'ตั้งค่าระบบ',
								icon: <Icon size={1} path={mdiCogOutline} />,
							},
						],
					})
				}

				if (
					profile.officerRoles.includes(OfficerRoles.ผู้บริหาร) ||
					profile.userLevel === OfficerUserLevel.SuperAdmin
				) {
					items.push({
						key: '/o',
						label: 'รายงาน',
						icon: <Icon size={1} path={mdiChartBoxPlusOutline} />,
						children: [
							{
								key: BellMeMenu.ReportOperationPerson,
								label: 'สรุปผลการปฏิบัติงานของเจ้าหน้าที่',
								icon: <Icon size={1} path={mdiAccountMultipleOutline} />,
							},
							{
								key: BellMeMenu.ReportOperationProblem,
								label: 'สรุปผลการปฏิบัติงานตามประเภทปัญหา',
								icon: <Icon size={1} path={mdiClipboardCheck} />,
							},
							{
								key: BellMeMenu.ReportDurationDivision,
								label: 'ระยะเวลาที่ใช้แก้ปัญหาของแต่ละกอง',
								icon: <Icon size={1} path={mdiAccountClock} />,
							},
							{
								key: BellMeMenu.ReportDurationProblem,
								label: 'ระยะเวลาที่ใช้แก้ไขปัญหาของแต่ละปัญหา',
								icon: <Icon size={1} path={mdiClockTimeFourOutline} />,
							},
						],
					})
				}
			}
		}

		return items
	}, [activeRole])

	const selectedKeys = useMemo(() => {
		const key = allMenu.find((item) => {
			return router.pathname === item
		})
		if (key) {
			if ([BellMeMenu.Config, BellMeMenu.DivisionConfig, BellMeMenu.ProblemConfig].includes(key as BellMeMenu)) {
				return [key, 'config']
			} else {
				return [key]
			}
		} else {
			return undefined
		}
	}, [menuItems, router.pathname])

	const mobileConfig = menuConfig[router.pathname as keyof typeof menuConfig]

	const onSwitchLineMenu = useCallback(() => {
		if (profile && defaultLiffObject && defaultLineOA) {
			setLoading(true)
			if (defaultLiffObject?.lineOAId === config.line.defaultLineOAId) {
				service.mis.switchLineMenu({ lineOAId: defaultLiffObject?.lineOAId }).then(() => {
					setLoading(false)
					defaultLiffObject?.liff?.closeWindow()
				})
			} else {
				const liffId =
					profile?.role === 'officer'
						? defaultLineOA?.lineLoginLiffIdPeople
						: defaultLineOA?.lineLoginLiffIdOfficer
				window.location.href = `https://liff.line.me/${liffId}/p/portal?freeze=1`
			}
		}
	}, [defaultLiffObject, defaultLineOA, profile])

	useEffect(() => {
		if (!screen.isPhone) {
			const handleClickOutside = (event: any) => {
				if (notiRef.current && !notiRef.current.contains(event.target)) {
					setOpenNotification(false)
				}
			}
			document.addEventListener('mousedown', handleClickOutside)
			return () => {
				document.removeEventListener('mousedown', handleClickOutside)
			}
		}
	}, [notiRef])

	useEffect(() => {
		if (user.profile) {
			socket.connect()
			socket.on('incident-update', () => {
				fetchNotificationCount()
			})
			socket.emit('join', { roomName: 'oss-bellme-inc-noti' })
			return () => {
				socket.disconnect()
			}
		}
	}, [user.profile])

	return (
		<>
			<Header
				className={`h-15 z-10 hidden border-b-4 border-gradient bg-header px-0 drop-shadow-lg lg:flex lg:pr-4`}
			>
				<div className='flex min-w-[300px] rounded-br-[40px] bg-white'>
					<div className='flex h-full flex-row'>
						<div className='mr-1 flex items-center drop-shadow'>
							{isClient && isDesktop && <AppLogo className='h-12 w-12 drop-shadow' />}
						</div>
						<div className='flex h-full flex-col justify-center'>
							<Typography className='text-base font-bold'>{title}</Typography>
							{subtitle && <Typography className='text-xs font-medium'>{subtitle}</Typography>}
						</div>
					</div>
				</div>
				<div className='ml-4 hidden flex-1 items-center overflow-hidden lg:flex'>
					{menuItems && menuItems.length > 0 ? (
						<Menu
							selectedKeys={selectedKeys}
							items={menuItems}
							mode='horizontal'
							onClick={(item) => {
								switch (item.key) {
									case BellMeMenu.Overview:
										window.dataLayer.push({ event: gaEvent.navbarClickMenuOverview })
										break
									case BellMeMenu.MyIncident:
										window.dataLayer.push({ event: gaEvent.navbarClickMenuMylist })
										break
									case BellMeMenu.Manage:
										window.dataLayer.push({ event: gaEvent.navbarClickMenuManageComplaint })
										break
									case BellMeMenu.UM:
										window.dataLayer.push({ event: gaEvent.navbarClickMenuManageUser })
										break
									case BellMeMenu.DivisionConfig:
										window.dataLayer.push({
											event: gaEvent.navbarClickSubmenuManageDeptInManageSystem,
										})
										break
									case BellMeMenu.ProblemConfig:
										window.dataLayer.push({
											event: gaEvent.navbarClickSubmenuManageProblemLineInManageSystem,
										})
										break
									case BellMeMenu.Config:
										window.dataLayer.push({
											event: gaEvent.navbarClickSubmenuManageResponsiblePersonInManageSystem,
										})
										break
									default:
										break
								}
								router.push(item!.key!.toString())
							}}
							onOpenChange={() => {
								if (!onOpenSubMenu) {
									window.dataLayer.push({ event: gaEvent.navbarClickMenuManageSystem })
									setOnOpenSubMenu(!onOpenSubMenu)
								} else {
									setOnOpenSubMenu(!onOpenSubMenu)
								}
							}}
							className='flex-1 border-b-0 bg-header bg-none'
						/>
					) : null}
					{screen.isDesktop && signedIn && (
						<div className='flex' ref={notiRef}>
							<div
								className='flex items-center'
								onClick={() => {
									setOpenNotification(!openNotification)

									window.dataLayer.push({ event: gaEvent.navbarClickMenuNotification })
								}}
							>
								<BadgeNotification className='mr-8 hover:cursor-pointer' count={notificationCount}>
									<BellNotificationDesktop />
								</BadgeNotification>
							</div>
							<Notification />
						</div>
					)}
				</div>
				<div className='block flex-1 md:hidden' />

				{signedIn ? (
					<AccountMenuButton className='lg:flex' />
				) : (
					<div className='hidden h-full flex-row items-center lg:flex'>
						<Button
							onClick={() => register()}
							shape='round'
							className='mr-2 w-36 rounded-full !border-white'
							type='primary'
						>
							ลงทะเบียน
						</Button>
						<Button
							shape='round'
							className='w-36 rounded-full border-none bg-white'
							onClick={() => signIn()}
						>
							เข้าสู่ระบบ
						</Button>
					</div>
				)}
			</Header>

			<Header className={`h-15 z-10 bg-header-mobile px-2 lg:hidden`}>
				<div className='h-full flex-1 items-center'>
					<div className='flex items-center justify-between'>
						{screen.isSubPage() ? (
							<div onClick={() => setSubPage(null)}>
								<Icon className='mx-auto ' path={mdiArrowLeft} size={1} />
							</div>
						) : (
							<div onClick={() => setOpenDrawer(true)}>
								<Icon className='mx-auto text-secondary' path={mdiMenu} size={1} />
							</div>
						)}

						<div className='flex flex-1 items-center justify-center align-middle'>
							{!screen.isSubPage() && mobileConfig?.icon && (
								<Icon size={1} path={mobileConfig.icon} className='' />
							)}
							<div className='ml-2'>{subPage?.title || mobileConfig?.title}</div>
						</div>
						{screen.isPhone && signedIn && (
							<div
								className='relative hover:cursor-pointer'
								onClick={() => {
									setOpenNotification(!openNotification)
									window.dataLayer.push({ event: gaEvent.navbarClickMenuNotification })
								}}
							>
								<BellNotificationMobile />
								{notificationCount > 0 && (
									<div className='absolute right-1 top-1 h-1.5 w-1.5 rounded-full bg-red-600'></div>
								)}
							</div>
						)}
					</div>
				</div>
				<Drawer
					className='lg:hidden'
					width={320}
					title={
						<div className='flex h-full flex-row items-center'>
							<div className='mr-1 flex items-center drop-shadow'>
								<AppLogo className='h-12 w-12 drop-shadow' />
							</div>
							<div className='flex h-full flex-1 flex-col justify-center'>
								<Typography className='text-base font-semibold text-primary'>{title}</Typography>
								{subtitle && (
									<Typography className='text-xs text-text-secondary'>{subtitle}</Typography>
								)}
							</div>
							<div onClick={() => setOpenDrawer(false)}>
								<Icon path={mdiClose} size={1} />
							</div>
						</div>
					}
					placement={'left'}
					closable={false}
					open={openDrawer}
					onClose={() => setOpenDrawer(false)}
				>
					<div className='flex h-full flex-col justify-between'>
						<div>
							{menuItems?.length ? (
								<Menu
									selectedKeys={selectedKeys}
									items={menuItems}
									mode='inline'
									className='flex-1 border-b-0 bg-none'
									onClick={(item) => {
										switch (item.key) {
											case BellMeMenu.Overview:
												window.dataLayer.push({ event: gaEvent.navbarClickMenuOverview })
												break
											case BellMeMenu.MyIncident:
												window.dataLayer.push({ event: gaEvent.navbarClickMenuMylist })
												break
											case BellMeMenu.Manage:
												window.dataLayer.push({ event: gaEvent.navbarClickMenuManageComplaint })
												break
											case BellMeMenu.UM:
												window.dataLayer.push({ event: gaEvent.navbarClickMenuManageUser })
												break
											case BellMeMenu.DivisionConfig:
												window.dataLayer.push({
													event: gaEvent.navbarClickSubmenuManageDeptInManageSystem,
												})
												break
											case BellMeMenu.ProblemConfig:
												window.dataLayer.push({
													event: gaEvent.navbarClickSubmenuManageProblemLineInManageSystem,
												})
												break
											case BellMeMenu.Config:
												window.dataLayer.push({
													event: gaEvent.navbarClickSubmenuManageResponsiblePersonInManageSystem,
												})
												break
											default:
												break
										}
										setOpenDrawer(false)
										router.push(item!.key!.toString())
									}}
									onOpenChange={() => {
										if (!onOpenSubMenu) {
											window.dataLayer.push({ event: gaEvent.navbarClickMenuManageSystem })
											setOnOpenSubMenu(!onOpenSubMenu)
										} else {
											setOnOpenSubMenu(!onOpenSubMenu)
										}
									}}
								/>
							) : null}
						</div>
						<div>
							{defaultLiffObject?.liff?.isInClient() &&
								defaultLiffObject?.liff?.isLoggedIn() &&
								defaultLiffObject?.lineOAId === config.line.defaultLineOAId &&
								user.profile?.isOfficer && (
									<div className='text-center'>
										<Button onClick={onSwitchLineMenu} type='link' loading={loading}>
											สลับผู้ใช้งาน (เจ้าหน้าที่/ประชาชน)
										</Button>
									</div>
								)}
							{defaultLiffObject?.liff?.isInClient() &&
								defaultLiffObject?.liff?.isLoggedIn() &&
								defaultLiffObject?.lineOAId !== config.line.defaultLineOAId &&
								user.profile?.isOfficer &&
								defaultLineOA?.muni?.muniId === officerAreaId && (
									<div className='text-center'>
										<Button onClick={onSwitchLineMenu} type='link' loading={loading}>
											สลับผู้ใช้งาน (เจ้าหน้าที่/ประชาชน)
										</Button>
									</div>
								)}
							<Divider />
							<div>
								{signedIn ? (
									<div>
										<AccountMenuButton
											showPopover={false}
											className='pointer-events-none flex justify-center'
										/>
										<div
											className='align-center mt-4 flex justify-center'
											onClick={() => {
												router.push(
													user.profile?.role === 'officer'
														? BellMeMenu.ProfileOfficer
														: BellMeMenu.ProfilePeople
												)
												setOpenDrawer(false)
											}}
										>
											<div>
												<Icon size={1} path={mdiPencilOutline} />
											</div>
											<div>ข้อมูลส่วนตัว</div>
										</div>
										<div
											className='align-center mt-4 flex justify-center'
											onClick={() => onClickSignOut()}
										>
											<div>
												<Icon size={1} path={mdiLogout} />
											</div>
											<div>ออกจากระบบ</div>
										</div>
									</div>
								) : (
									<div className='h-full  items-center lg:flex'>
										<Button
											shape='round'
											className='mb-2 w-full rounded-full'
											onClick={() => signIn()}
											type='primary'
										>
											เข้าสู่ระบบ
										</Button>
										<Button
											shape='round'
											className='w-full rounded-full'
											onClick={() => register()}
										>
											ลงทะเบียน
										</Button>
									</div>
								)}
							</div>
						</div>
					</div>
				</Drawer>
			</Header>
		</>
	)
}
