import SpinFC from 'antd/lib/spin'

export default function SplashScreen({ show }: { show?: boolean }) {
	const className = !show ? 'opacity-0 pointer-events-none' : 'opacity-100'
	return (
		<div
			className={`fixed inset-0 z-50 flex flex-col content-center items-center justify-center bg-background-primary transition-opacity ${className}`}
		>
			<SpinFC size='large' />
		</div>
	)
}
