import rootCss from '@/styles/root.css'
import { PropsWithChildren, useEffect, useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Layout } from 'antd'
import AppBar from '@/components/AppBar'
import { useAllowedAll, useUser } from '@/contexts/user'
import { useApp, useResponsive } from '@/contexts/app'
import { Notification } from '@/components/pages/people/notification/Notification'

const defualtTitle = 'BELL ME'
const defaultSubtitle = 'ระบบแจ้งเหตุร้องเรียน'

export interface LayoutProps extends PropsWithChildren {
	appTitle?: string
	appSubtitle?: string
}

export default function MainLayout(props: LayoutProps) {
	const { appTitle = defualtTitle, appSubtitle = defaultSubtitle, children } = props
	const router = useRouter()
	const [initialized, setInitialized] = useState(false)
	const user = useUser()
	const allowed = useAllowedAll()
	const screen = useResponsive()
	const { openNotification } = useApp()

	useEffect(() => {
		if (!allowed) return
		setInitialized(true)
	}, [allowed, user.isOfficer])

	return (
		<>
			<style jsx global>
				{rootCss}
			</style>
			<Head>
				<base href={router.basePath + '/'} />
				<title>{appTitle}</title>
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<meta name='description' content='Bellme ระบบแจ้งเหตุ' />
				<meta name='keywords' content='Smart, City, เทศบาล, Bellme, ระบบแจ้งเหตุ' />
				{/* <meta name='author' content='MAPBOSS Co., Ltd.' /> */}
				<link rel='icon' href='logo.svg' />
				<link rel='manifest' href='manifest.webmanifest' />
			</Head>
			<Layout className='h-full'>
				{appTitle && <AppBar title={appTitle} subtitle={appSubtitle} />}
				{initialized ? screen.isPhone && openNotification ? <Notification /> : children : null}
			</Layout>
		</>
	)
}
