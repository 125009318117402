import { mdiTrayArrowUp } from '@mdi/js'
import Icon from '@mdi/react'
import { Spin, Upload as Component, UploadFile, UploadProps } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { PhotoProvider } from 'react-photo-view'
import { notification } from '../notification'
import 'react-photo-view/dist/react-photo-view.css'
import SkeletonImage from 'antd/lib/skeleton/Image'
import { Paper } from '../paper'

export function Upload(
	props: UploadProps & {
		loading?: boolean
		mark?: boolean
		maxSize?: number
		readOnly?: boolean
		label?: string
		required?: boolean
	}
) {
	const {
		loading = false,
		required = false,
		label,
		fileList,
		maxCount = 1,
		multiple = false,
		maxSize = 15,
		readOnly = false,
		mark = false,
		beforeUpload,
		onChange,
		accept = ['.png', '.jpg', '.jpeg', '.pdf'].join(),
		disabled,
		...allProps
	} = props

	const [markState, setMarkState] = useState(mark)

	const busy = useMemo(() => fileList?.some((f) => f.status === 'uploading'), [fileList])

	const files = useMemo(() => {
		if (fileList && fileList.length > 0) {
			return fileList
		}
		return []
	}, [fileList, readOnly, required])

	const markedFiles = useMemo<UploadFile[]>(
		() =>
			files.map((file) => ({
				...file,
				status: markState === true ? 'error' : file.status,
			})),
		[files, markState]
	)

	const itemRender = useCallback<NonNullable<UploadProps['itemRender']>>((originNode, file, fileList, actions) => {
		return file.status === 'uploading' ? (
			<Paper className='relative h-full w-full'>
				<div className='absolute inset-0 flex items-center justify-center'>
					<Spin />
				</div>
			</Paper>
		) : (
			originNode
		)
	}, [])

	return (
		<PhotoProvider maskOpacity={0.9} toolbarRender={() => <div className='ml-4 flex-1'>{label}</div>}>
			<Component
				disabled={disabled || busy || readOnly || loading}
				fileList={markedFiles}
				accept={accept}
				multiple={multiple}
				maxCount={maxCount}
				itemRender={itemRender}
				showUploadList={{
					showRemoveIcon: !busy && !readOnly,
				}}
				beforeUpload={(file, fileList) => {
					const isGt15M = file.size / 1024 / 1024 > maxSize
					if (isGt15M) {
						const errorMessage = `ไฟล์ต้องมีขนาดไม่เกิน ${maxSize}MB`
						notification.error(errorMessage)
						onChange?.({
							fileList: [],
							file: {
								uid: file.uid,
								name: file.name,
								fileName: file.name,
								type: file.type,
								status: 'error',
								error: errorMessage,
							},
						})
						return false
					}
					return beforeUpload?.(file, fileList)
				}}
				listType={'picture-card'}
				className={`
            [&_.ant-upload-list-picture-card_.ant-upload-list-item-actions]:flex
            [&_.ant-upload-list-picture-card_.ant-upload-list-item-actions]:items-center
            [&_.ant-upload-list-item-actions>*]:leading-[0]
            [&_.ant-upload.ant-upload-select-picture-card]:rounded-xl
            [&_.ant-upload-list-item]:rounded-xl
            `}
				{...allProps}
			>
				{!busy && loading ? (
					<SkeletonImage active />
				) : fileList!.length < maxCount ? (
					<Icon path={mdiTrayArrowUp} size={1} />
				) : null}
			</Component>
		</PhotoProvider>
	)
}
