import moment, { Moment } from "moment";

//#region set default format and override buddhist year
moment.updateLocale("th", {
  longDateFormat: {
    LTS: "h:mm:ss A",
    LT: "h:mm A",
    L: "DD MMMM BBBB",
    LL: "Do MMMM BBBB",
    LLL: "MMMM Do BBBB LT",
    LLLL: "dddd, MMMM Do BBBB LT",
  },
  invalidDate: "รูปแบบวันที่ไม่ถูกต้อง",
});
const momentFormat: Moment["format"] = moment.fn.format;
moment.fn.format = function (this: Moment, format?): string {
  const count = (format?.match(/Y/g) || []).length;
  const regY = count === 2 ? "YY" : "YYYY";
  const regB = count === 2 ? "BB" : "BBBB";
  const regDefaultYear = new RegExp(regY, "ig");
  format = format?.replace?.(regDefaultYear, regB);
  const out = momentFormat.apply(this, [format]);
  const regBuddhist = new RegExp(regB, "ig");

  if (!out.match(regBuddhist)) {
    return out;
  }

  let year = this.toDate()
    .toLocaleString("en-US-u-ca-buddhist")
    .match(/\d{1,2}\/\d{1,2}\/(\d+)\s\w{1,2}/)?.[1];

  if (count === 2) {
    year = year?.slice(2);
  }
  return out.replace(regBuddhist, year || "");
};

//#endregion
