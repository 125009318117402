export enum OfficerRoles {
	เจ้าหน้าที่รับเรื่อง = 1,
	เจ้าหน้าที่ปฏิบัติงาน = 2,
	ผู้อนุมัติงาน = 3,
	ผู้บริหาร = 4,
}

export enum OfficerUserLevel {
	SuperAdmin = 1,
	Admin = 2,
	User = 3,
}

export enum AuthType {
	Line = 'l',
	Arv = 'a',
}
