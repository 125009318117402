import { notification as antdNoti } from 'antd'
import { ArgsProps } from 'antd/lib/notification'

export const notification = {
	error: (description: string, message?: string, args?: Omit<ArgsProps, 'message' | 'description'>) => {
		return antdNoti.error({
			message,
			description,
			...args,
		})
	},
	success: (description: string, message?: string, args?: Omit<ArgsProps, 'message' | 'description'>) => {
		return antdNoti.success({
			message,
			description,
			...args,
		})
	},
	warning: (description: string, message?: string, args?: Omit<ArgsProps, 'message' | 'description'>) => {
		return antdNoti.warning({
			message,
			description,
			...args,
		})
	},
}

antdNoti.config({
	placement: 'bottomLeft',
})
