import Body from '@/components/common/text/Body'
import Subtitle from '@/components/common/text/Subtitle'
import { Upload } from '@/components/common/upload'
import IncidentTag from '@/components/shared/IncidentTag'
import { BellMeMenu, getImageUrl, videoIcon } from '@/utils/incident'
import { GetIncidentNotificationDtoOut } from '@interface/dto/mis/incident.dto-out'
import { Divider } from 'antd'
import moment from 'moment'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useApp } from '@/contexts/app'
import NotificationTag from '@/components/shared/notificationTag'
import { gaEvent } from '@/utils/google-analytic'
export interface NotificationItemProps extends PropsWithChildren {
	data: GetIncidentNotificationDtoOut
}

export const IncidentNotificationItem: FC<NotificationItemProps> = (props) => {
	const { data } = props
	const { setOpenNotification } = useApp()
	const router = useRouter()
	const [fileList, setFileList] = useState<any[]>([])

	useEffect(() => {
		if (data.incidentFiles?.length) {
			let item = data.incidentFiles[0]
			const isVideo = item.fileType?.includes('video')
			item = isVideo && data.incidentFiles.length > 1 ? data.incidentFiles[1] : item
			if (item.fileType?.includes('video')) {
				// กรณีอัพ video มาอันเดียว
				setFileList([
					{
						uid: item.fileId,
						name: null,
						status: 'done',
						url: null,
					},
				])
			} else {
				setFileList([
					{
						uid: item.fileId,
						name: item.fileName,
						status: 'done',
						thumbUrl: isVideo ? videoIcon : getImageUrl(item.id as string, true),
						url: getImageUrl(item.id as string),
					},
				])
			}
		} else {
			setFileList([
				{
					status: 'done',
					thumbUrl: undefined,
				},
			])
		}
	}, [data])

	const onClickIncidentItem = () => {
		window.dataLayer.push({ event: gaEvent.navbarClickSelectComplaintInListNotificationIn })

		setOpenNotification(false)
		router.push({ pathname: `${BellMeMenu.Incident}`, query: { id: data.id } })
	}

	return (
		<div className='hover:cursor-pointer' onClick={onClickIncidentItem}>
			<div className='flex w-full'>
				<Body>
					<Upload
						readOnly
						fileList={fileList}
						className={classNames(
							'[&_.ant-upload-list-picture-card-container]:h-[80px] [&_.ant-upload-list-picture-card-container]:w-[80px]',
							'[&_.ant-upload-list-item]:rounded-lg',
							'pointer-events-none mr-4 max-h-[80px] max-w-[80px]'
						)}
					/>
				</Body>
				<div className='flex w-full'>
					<div>
						<div>
							{data.status && (
								<Body className='mb-2'>
									<NotificationTag className='mb-1' statusId={data.status.id} label={''} />
								</Body>
							)}
							<div className='flex flex-row '>
								<div className=' flex flex-1 flex-row'>
									<Subtitle bold className='mr-5 text-sm'>
										เลขรับแจ้ง {data.incNo}
									</Subtitle>
									<Subtitle bold className='text-sm text-[#00AA86]'>
										ผู้แจ้ง : {data.email}
									</Subtitle>
								</div>
							</div>
						</div>
						<Divider className='my-2 ' />
						<Body>{data.description}</Body>
						<Body>ตำแหน่งพื้นที่: {data.locationDescr}</Body>
						<div className='flex'>
							<Body className='mr-2 border-r border-solid border-[#E9E9E9] pr-2'>
								ประเภทหลัก: {data.problemType?.name}
							</Body>

							<Body>ประเภทย่อย: {data.problemSubtype?.name}</Body>
						</div>
						<div className='flex justify-between'>
							<Body>
								<span className='mr-2'>Tag:</span>
								{data.incidentTagRelateds?.length
									? data.incidentTagRelateds?.map((d) => (
											<IncidentTag className='mb-1' key={d.tag?.name} label={d.tag?.name} />
									  ))
									: '-'}
							</Body>
							<Body>{`วันที่แจ้ง : ${moment(data.submittedDt).format('DD/MM/YYYY')}`}</Body>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
