import { Modal } from '@/components/common/modal/Modal'
import { useIdentity } from '@/modules/arv/identity'
import { BellMeMenu } from '@/utils/incident'
import { OfficerUserLevel } from '@interface/config/am.config'
import { UserRole } from '@interface/dto/am.dto'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useState } from 'react'
import { UserContext } from './context'

const peopleHome = BellMeMenu.Overview
const officerHome = BellMeMenu.Overview

export function useUser() {
	return useContext(UserContext)
}

export function useAllowedOfficer() {
	const { activeRole, unset, profile } = useUser()
	const isAllow = useAllowedRoles(['officer'])
	const router = useRouter()
	const identity = useIdentity()
	useEffect(() => {
		if (isAllow === 0) return
		if (isAllow === false) {
			router.replace('/')
		}
	}, [activeRole, isAllow])

	const isSuperAdmin = profile?.role === 'officer' && profile.userLevel === OfficerUserLevel.SuperAdmin

	const isHasPermission = profile?.role === 'officer' && profile.officerArea && profile.officerRoles.length > 0

	useEffect(() => {
		if (!isAllow) return
		if (profile?.role !== 'officer') return
		if (isSuperAdmin) return
		if (isHasPermission) return
		Modal.error({
			icon: false,
			title: 'ระบบได้รับคำขอสำหรับขอเข้าใช้งานระบบแล้ว',
			content: (
				<>
					เนื่องจากท่านยังไม่ได้รับสิทธิ์เพื่อเข้าใช้งาน หรือถูกระงับการใช้งาน
					<br />
					กรุณารอเจ้าหน้าที่กำหนดสิทธิ์ หรือติดต่อผู้ดูแลระบบ
				</>
			),
			okButtonProps: { className: 'hidden' },
			cancelText: 'ออกจากระบบ',
			okCancel: true,
			closable: false,
		}).then(() => {
			identity.signOut().then(() => unset())
		})
	}, [isAllow, profile?.role])

	return isAllow && (isSuperAdmin || isHasPermission)
}

export function useAllowedPublic() {
	const { activeRole } = useContext(UserContext)
	const isAllow = useAllowedRoles(['people'])
	const router = useRouter()
	useEffect(() => {
		if (isAllow === 0) return
		if (isAllow === false) {
			if (activeRole === 'officer') {
				router.replace(officerHome)
			} else {
				router.replace('/')
			}
		}
	}, [activeRole, isAllow])
	return isAllow
}

export function useAllowedAnonymous() {
	const { activeRole } = useContext(UserContext)
	const isAllow = useAllowedRoles([null])
	const router = useRouter()
	useEffect(() => {
		if (isAllow === 0) return
		if (isAllow === false) {
			if (activeRole === 'officer') {
				router.replace(officerHome)
			} else if (activeRole === 'people') {
				router.replace(peopleHome)
			}
		}
	}, [activeRole, isAllow])
	return isAllow
}

export function useAllowedAll() {
	const isAllow = useAllowedRoles()
	return isAllow
}

export function useAllowedRoles(roles?: UserRole[]) {
	const { signedIn, activeRole } = useContext(UserContext)
	const [isAllow, setIsAllow] = useState<boolean | 0>(0)

	useEffect(() => {
		if (typeof signedIn === 'undefined') {
			setIsAllow(0)
			return
		}
		if (!roles) {
			return setIsAllow(true)
		}

		if (roles.includes(activeRole!)) {
			setIsAllow(true)
		} else {
			setIsAllow(false)
		}
	}, [signedIn, activeRole])
	return isAllow
}

export function useAllowedLevels(levels: OfficerUserLevel[]) {
	const isAllow = useAllowedRoles(['officer'])
	const { profile } = useUser()
	return useMemo(() => {
		if (!isAllow) return false
		if (profile?.role !== 'officer') return false
		if (!profile.userLevel) return false
		return levels.includes(profile.userLevel)
	}, [levels, isAllow, profile])
}
