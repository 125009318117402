import { Tag } from 'antd'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import { IncStatus, statusConfig } from './incident'
import { useMicroservice } from '@/contexts/service'
import { GetLookupOutDto } from '@interface/dto/mis'
import { useRouter } from 'next/router'
import { BellMeMenu } from '@/utils/incident'
import { useUser } from '@/contexts/user'

interface Props {
	statusId?: IncStatus | undefined
	label: string | undefined
	className?: string
}

const IncidentTag: FC<Props> = ({ statusId, label, className }: Props) => {
	const router = useRouter()
	const service = useMicroservice()
	const user = useUser()
	const [statusList, setStatusList] = useState<GetLookupOutDto[]>([])
	const isOverviewPage = router.pathname === BellMeMenu.Overview
	const isPeopleApp = !user.isOfficer

	useEffect(() => {
		service.mis.getLookupData('inc-status', true).then((res) => {
			setStatusList(res)
		})
	}, [])

	const color = useMemo(() => {
		if (statusId && statusList.length) {
			if (isOverviewPage) {
				const publicStatus = statusList.find((item) => item.id === statusId)?.inc_status_public_id as IncStatus
				return statusConfig[publicStatus].color
			} else {
				if (isPeopleApp && statusId === IncStatus.Approve) {
					return statusConfig[IncStatus.Wip].color
				} else {
					return statusConfig[statusId].color
				}
			}
		} else {
			return '#A6A6B0'
		}
	}, [statusId, statusList])

	const newLabel = useMemo(() => {
		if (statusList.length) {
			if (isOverviewPage) {
				const publicStatus = statusList.find((item) => item.id === statusId)?.inc_status_public_id
				return statusList.find((item) => item.id === publicStatus)?.name
			} else {
				if (isPeopleApp && statusId === IncStatus.Approve) {
					return statusList.find((item) => item.id === IncStatus.Wip)?.name
				} else {
					return statusList.find((item) => item.id === statusId)?.name
				}
			}
		}
	}, [statusId, statusList])

	return (
		<Tag color={color} className={className}>
			{label || newLabel || ''}
		</Tag>
	)
}

export default memo(IncidentTag)
