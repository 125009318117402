import { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { Liff } from '@line/liff'
import { useMicroservice } from '@/contexts/service'
import { LineOaEntity } from '@interface/entities'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { sleep } from '@/utils/sleep'
const { publicRuntimeConfig: config } = getConfig()

export enum LineStateName {
	LineOA = 1,
	DefaultLineOA = 2,
}

export enum LineEvent {
	doLogin = 1,
	doLogout = 2,
	doNothing = 3,
}

interface LiffObjectItem {
	lineOAId: string
	liff: Liff | null
}
interface LineContextValue {
	liffObject: LiffObjectItem | null
	lineOA: LineOaEntity | null
	defaultLiffObject: LiffObjectItem | null
	defaultLineOA: LineOaEntity | null
	detectConnect: string | null
	detectDisconnect: string | null
}
interface LineContextFunction {
	setLiffObject: (liff: LiffObjectItem | null) => void
	setLineOA: (lineOA: LineOaEntity) => void
	setDefaultLiffObject: (liff: LiffObjectItem | null) => void
	setDefaultLineOA: (lineOA: LineOaEntity) => void
	setDetectConnect: (rd: string) => void
	setDetectDisconnect: (rd: string) => void
	liffInit: (liffId: string, liffState: LineStateName, event: LineEvent) => void
	liffLogin: (liffId: string) => Promise<void>
	liffLogout: (liffId: string, lineOAId: string) => Promise<void>
	saveLineToken: (lineOAId: string) => Promise<boolean>
}
type LineContextProps = LineContextValue & LineContextFunction

const defaultValue: LineContextValue = {
	liffObject: null,
	lineOA: null,
	defaultLiffObject: null,
	defaultLineOA: null,
	detectConnect: null,
	detectDisconnect: null,
}

export const LineContext = createContext<LineContextProps>({
	...defaultValue,
	setLiffObject(liff: LiffObjectItem | null) {},
	setLineOA(lineOA: LineOaEntity) {},
	setDefaultLiffObject(liff: LiffObjectItem | null) {},
	setDefaultLineOA(lineOA: LineOaEntity) {},
	setDetectConnect(rd: string) {},
	setDetectDisconnect(rd: string) {},
	async liffInit(liffId: string, liffState: LineStateName, event: LineEvent) {},
	async liffLogin(liffId: string) {},
	async liffLogout(liffId: string, lineOAId: string) {},
	async saveLineToken(lineOAId: string) {
		return false
	},
})

export interface LineProviderProps extends PropsWithChildren {
	isOfficer: boolean
}

export function LineProvider({ children, isOfficer }: LineProviderProps) {
	const service = useMicroservice()
	const router = useRouter()

	// ข้อมูล liff และ lineOA ใช้งานแบบ dynamic เพื่อใช้ในการ connect line
	const [liffObject, setLiffObject] = useState<LiffObjectItem | null>(null)
	const [lineOA, setLineOA] = useState<LineOaEntity | null>(null)
	// ข้อมูล liff และ lineOA ใช้งานแบบ static เป็นข้อมูล Default
	const [defaultLiffObject, setDefaultLiffObject] = useState<LiffObjectItem | null>(null)
	const [defaultLineOA, setDefaultLineOA] = useState<LineOaEntity | null>(null)
	// detect เมื่อมีการ เชื่อมต่อ/ยกเลิกเชื่อมต่อ กับข้อมูลในระบบ
	const [detectConnect, setDetectConnect] = useState<string | null>(null)
	const [detectDisconnect, setDetectDisconnect] = useState<string | null>(null)

	const liffInit = async (liffId: string, liffState: LineStateName, event: LineEvent = LineEvent.doNothing) => {
		// console.log('---------------liffInit-----------------')
		if (!liffId) return
		import('@line/liff')
			.then((liff) => liff.default)
			.then((liff) => {
				// console.log('######## liffId before init', liffId)
				liff.init({ liffId: liffId as string })
					.then(async () => {
						// console.log('######## liffId after init', liff.id)
						if (event === LineEvent.doLogin) {
							liff.ready.then(async () => {
								// console.log('######## liff ready & login', liff.id)
								liff.login({ redirectUri: window.location.href })
							})
						} else if (event === LineEvent.doLogout) {
							liff.ready.then(async () => {
								// console.log('######## liff ready & logout', liff.id)
								liff.logout()
							})
						}
						const lineOAItem = await service.mis.getLineOA({ liffId: liffId })
						const liffObj = {
							lineOAId: lineOAItem.data.id as string,
							liff: liff,
						}
						if (liffState === LineStateName.LineOA) {
							// console.log('######## Set LineOA')
							setLiffObject(liffObj)
							setLineOA(lineOAItem?.data)
						} else if (liffState === LineStateName.DefaultLineOA) {
							// console.log('######## Set DefaultLineOA')
							setDefaultLiffObject(liffObj)
							setDefaultLineOA(lineOAItem?.data)
						}
					})
					.catch((error: Error) => {
						console.error('LIFF init failed.')
					})
			})
	}

	const liffLogin = async (liffId: string) => {
		// console.log('---------------liffLogin-----------------')
		// console.log('######## liffId', liffId)
		await liffInit(liffId, LineStateName.LineOA, LineEvent.doLogin)
	}

	const liffLogout = async (liffId: string, lineOAId: string): Promise<void> => {
		// console.log('---------------liffLogout-----------------')
		// console.log('######## liffId', liffId)
		// console.log('######## lineOAId', lineOAId)
		await liffInit(liffId as string, LineStateName.LineOA, LineEvent.doLogout)
		await service.mis.disconnectLine({ lineOAId })
		const rd = (Math.random() + 1).toString(36).substring(7)
		setDetectDisconnect(rd)
	}

	const saveLineToken = async (lineOAId: string): Promise<boolean> => {
		// console.log('---------------saveLineToken-----------------')
		if (!liffObject) return false
		// console.log('######## liffId', liffObject?.liff?.id)
		const lineProfile = await liffObject?.liff?.getProfile()
		const lineToken = liffObject?.liff?.getIDToken()
		// console.log('######## lineToken', lineToken)
		// console.log('######## lineProfile?.userId', lineProfile?.userId)
		// console.log('######## lineOAId', lineOAId)
		const res = await service.mis.addLine({
			lineToken: lineToken as string,
			lineUserId: lineProfile?.userId,
			lineOAId: lineOAId,
		})
		const rd = (Math.random() + 1).toString(36).substring(7)
		setDetectConnect(rd)
		return res.success
	}

	useEffect(() => {
		// console.log('---------------useEffect-----------------')
		// มาจาการที่ liff redirect หลังจากการ login จะมี liffClientId มาใน query
		const { liffClientId } = router.query
		const isRedirectFromLiff = !!liffClientId
		// มาจาการที่เปิดผ่าน liff จะมี client_id มาใน hash แล้วจะ redirect ไปอีก
		const hashParams = new URLSearchParams(window.location.hash)
		const clientId = hashParams.get('client_id')
		const isOpenFromLiff = !!clientId
		// มาจากการ liff redirect หรือเปิดจาก Liff App จะไม่ทำงาน
		if (!isRedirectFromLiff && !isOpenFromLiff) {
			import('@line/liff')
				.then((liff) => liff.default)
				.then((liff) => {
					// console.log('######## DefaultLiffId', defaultLiffObject?.liff?.id)
					// ถ้ายังไม่มี default จะ init ตัว default
					if (!defaultLiffObject) {
						if (liff.isInClient()) {
							// ถ้าเป็น liff app จะเอา liffId จาก session ที่ set ไว้ก่อนที่ liff จะ redirect มา
							const liffId = sessionStorage?.getItem('liff-login-id-default')
							// console.log('######## initLiffDefault liffId', liffId)
							liffInit(liffId as string, LineStateName.DefaultLineOA)
						} else {
							// ถ้าไม่ใช้ liff app จะเอา liffId จาก database
							service.mis.getLineOA({ lineOAId: config.line.defaultLineOAId }).then((result) => {
								const liffId = isOfficer
									? result.data.lineLoginLiffIdOfficer
									: result.data.lineLoginLiffIdPeople
								// console.log('######## initLiffDefault liffId', liffId)
								liffInit(liffId as string, LineStateName.DefaultLineOA)
							})
						}
					}
					const liffId = sessionStorage?.getItem('liff-login-id')
					// console.log('######## liff-login-id', liffId)
					// ถ้าเจอว่ามี session liff-login-id แปลว่ามีการที่ liff redirect มาจากการ login
					// ซึ่งก่อนที่ liff จะ redirect กลับมาที่ app ได้มีการ set session liff-login-id ไว้
					// ถ้ามีตัว default แล้ว และมี liff-login-id จะ init liff ที่พึ่ง login ไป เพื่อตรวจสอบว่า login สำเร็จหรือไม่ (อยู่ใน AppBar)
					if (defaultLiffObject && liffId) {
						liffInit(liffId, LineStateName.LineOA)
					}
				})
		}
		// ทุกครั้งที่ Redirect จาก liff login จะต้อง init liff ที่ทำการ login ตัว liff ถึงจะ Redirect กลับมาที่ app
		if (isRedirectFromLiff) {
			// console.log('######## isRedirectFromLiff', isRedirectFromLiff)
			// console.log('######## liffClientId', liffClientId)
			const handleLiffInit = async () => {
				const result = await service.mis.getLineOA({ lineLoginChannelId: liffClientId as string })
				const liffId = isOfficer ? result.data.lineLoginLiffIdOfficer : result.data.lineLoginLiffIdPeople
				// console.log('######## set liff-login-id', liffId)
				sessionStorage.setItem('liff-login-id', liffId as string)
				liffInit(liffId as string, LineStateName.LineOA)
			}
			handleLiffInit()
		}
		// ทุกครั้งที่เปิดจาก liff จะต้อง init liff ที่ทำการเปิด ตัว liff ถึงจะ Redirect กลับมาที่ app
		if (isOpenFromLiff) {
			// console.log('######## isOpenFromLiff', isOpenFromLiff)
			const liffState = (router.query?.['liff.state'] as string) || ''

			// เกิดจากตอน switch liff people/officer จะ set freeze-portal เพื่อบอกในหน้า portal ไม่ต้อง redirect อีก
			if (liffState.includes('/p/portal?freeze=1')) {
				sessionStorage.setItem('freeze-portal', 'true')
			}
			const handleLiffInit = async () => {
				const result = await service.mis.getLineOA({ lineLoginChannelId: clientId as string })
				const liffId = isOfficer ? result.data.lineLoginLiffIdOfficer : result.data.lineLoginLiffIdPeople
				// console.log('######## set liff-login-id-default', liffId)
				sessionStorage.setItem('liff-login-id-default', liffId as string)
				liffInit(liffId as string, LineStateName.DefaultLineOA)
			}

			handleLiffInit()
		}
	}, [router.query, defaultLiffObject])

	const contextValue = useMemo<LineContextProps>(() => {
		return {
			...defaultValue,
			liffObject,
			lineOA,
			defaultLiffObject,
			defaultLineOA,
			detectConnect,
			detectDisconnect,
			setLiffObject,
			setLineOA,
			setDefaultLiffObject,
			setDefaultLineOA,
			setDetectConnect,
			setDetectDisconnect,
			liffInit,
			liffLogin,
			liffLogout,
			saveLineToken,
		}
	}, [
		liffObject,
		lineOA,
		defaultLiffObject,
		defaultLineOA,
		detectConnect,
		detectDisconnect,
		setLiffObject,
		setLineOA,
		setDefaultLiffObject,
		setDefaultLineOA,
		setDetectConnect,
		setDetectDisconnect,
		liffInit,
		liffLogin,
		liffLogout,
		saveLineToken,
	])

	return <LineContext.Provider value={contextValue}>{children}</LineContext.Provider>
}
